import { ImageOverlay } from "@components/ImageOverlay";
import { Loading } from "@components/Loading";
import { Timestamp, doc, getDoc, updateDoc } from "@firebase/firestore";
import { useTranslatedKey } from "@helpers/useTranslatedKey";
import { useTranslation } from "@helpers/useTranslation";
import { useProgressStore } from "@pages/challenges";
import { getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import { FC, useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { FirebaseContext } from "src/helpers/firebase";
import { useConfetti } from "src/helpers/useConfetti";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { useTeam } from "src/helpers/useTeam";
import { useToast } from "src/helpers/useToast";

type Props = {
  challenge: GreetingChallenge;
  solution: GreetingSolution;
  teamId: string;
  eventId: string;
};

export const Greeting: FC<Props> = (props) => {
  const { id } = props.challenge;
  const { eventId, teamId } = props;
  const { t } = useTranslation("challenges");
  const { firestore, functions, storage } = useContext(FirebaseContext);
  const [image, setImage] = useState(undefined);
  const [startingImageUrl, setStartingImageUrl] = useState(undefined);
  const navigate = useEventNavigate();
  const [team] = useTeam();
  const [submitting, setSubmitting] = useState(false);
  useConfetti("submit-button");
  const toast = useToast();
  const [updateTeamPoints, _, error] = useHttpsCallable(functions, "updateTeamPoints");

  const { getTranslatedValue } = useTranslatedKey();
  const title = getTranslatedValue(props.challenge, "title");
  const copy = getTranslatedValue(props.challenge, "copy");
  const [greeting, setGreeting] = useState(props?.solution?.greeting ?? "");

  // @ts-ignore
  const setChallengeLoadingState = useProgressStore((state) => state.setChallenge);

  useEffect(() => {
    if (error) {
      toast(t("problemOccured"), "🤷‍♂️", "error");
      console.error("Problem occured in updateTeamPoints function: ", error);
    }
  }, [error]);

  useEffect(() => {
    if (!eventId || !teamId || !storage) return;

    const getStartingImageUrl = async () => {
      const imagePath = `events/${eventId}/teams/${teamId}/avatar_heavy`;
      const imageRef = storageRef(storage, imagePath);
      try {
        const url = await getDownloadURL(imageRef);
        setStartingImageUrl(url);
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 4000));
        try {
          const url = await getDownloadURL(imageRef);
          setStartingImageUrl(url);
        } catch (error) {
          toast(t("problemOccuredRefresh"), "⏳", "loading", 5000);
          console.error("Error getting greeting image url: ", error);
        }
      }
    };

    getStartingImageUrl();
  }, [eventId, teamId, storage]);

  const updatePromise = async () => {
    try {
      setChallengeLoadingState(id, true);
      // Upload images
      const imagePath = `events/${eventId}/teams/${teamId}/solutions/${id}`;
      const imageRef = storageRef(storage, imagePath);
      await uploadBytes(imageRef, image);

      // Update firestore
      await updateTeamPoints({ teamId, challengeId: id, eventId, greeting });
      const teamDocRef = doc(firestore, `events/${eventId}/teams/${teamId}`);

      const teamDoc = await getDoc(teamDocRef);
      const teamData = teamDoc.data();
      const solutionsMap = teamData?.solutionsMap || {};
      solutionsMap[id] = Timestamp.now();
      await updateDoc(teamDocRef, { solutionsMap });
    } catch (error) {
      toast(t("problemOccured"), "🤷‍♂️", "error");
      console.error(error);
    } finally {
      // Give time to photo proccessing lambda to finish
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setChallengeLoadingState(id, false);
    }
  };

  const submit = async () => {
    if (!team) {
      toast(t("problemOccured"), "🤷‍♂️", "error");
      console.error(`Team is undefined. Team: ${team}, event: ${eventId}`);
      return;
    }

    setSubmitting(true);
    updatePromise(); // Don't wait for this to finish for better UX
    navigate("/challenges");
  };

  const onTimeout = () => {
    toast(t("imageRenderingTimeout"), "⏳", "error", 8000);
    console.error(`Image rendering took too long. Eventid: ${eventId}`);
    navigate("/challenges");
  };

  useEffect(() => {
    if (image) submit();
  }, [image]);

  const [render, setRender] = useState(false);

  return (
    <>
      <Loading loading={!startingImageUrl}>
        <h1 className="mx-4 mt-16 mb-8 text-center  text-4xl font-semibold">{title}</h1>
        <div className="mt-4 mb-8 flex flex-col items-center">
          <div className="mx-6 flex max-w-lg flex-col justify-center">
            <>
              {props?.solution?.completed ? (
                <div className="flex max-w-lg flex-col justify-center">
                  <h2 className="text-center text-xl font-bold">{t("challengeDone")}</h2>
                  <div className="mt-4 mb-4 text-center text-lg">{t("challengeDoneSummaryGreeting")}</div>
                </div>
              ) : null}
              <p className="text-md mt-6 mb-4 text-center">{copy}</p>
              <div className={`px-4 py-4 ${startingImageUrl ? "" : "hidden"}`}>
                <ImageOverlay
                  startingImageUrl={startingImageUrl}
                  placeholder={t("enterCaption")}
                  defaultValue={greeting}
                  onNewImage={setImage}
                  onNewCaption={(caption) => setGreeting(caption)}
                  onTimeout={onTimeout}
                  render={render}
                />
              </div>
            </>
            <div className="mb-12 flex justify-center">
              <button
                disabled={submitting}
                onClick={() => {
                  setSubmitting(true);
                  setRender(true);
                }}
                className={`btn-primary btn ${submitting ? "loading" : ""}`}
                id="submit-button"
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </Loading>
    </>
  );
};
