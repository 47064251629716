import { EventInfo } from "@components/EventInfo";
import { DraggableEventInfo } from "@components/EventInfo/DraggableEventInfo";
import { InstantImage } from "@components/FirebaseCachedImage/InstantImage";
import { HighscoreTable } from "@components/HighscoreTable";
import { CrossIcon } from "@components/Icons/Cross";
import { CycleIcon } from "@components/Icons/Cycle";
import { FullscreenIcon } from "@components/Icons/Fullscreen";
import { GridIcon } from "@components/Icons/Grid";
import { HighscoresIcon } from "@components/Icons/Highscores";
import { ImageIcon } from "@components/Icons/Image";
import { QRIcon } from "@components/Icons/QR";
import { Loading } from "@components/Loading";
import { useTranslation } from "@helpers/useTranslation";
import { Timestamp } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { ImageCacheContext } from "src/helpers/imageCache";
import { useBranding } from "src/helpers/useBranding";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useFullScreen } from "src/helpers/useFullScreen";
import { useInterval } from "src/helpers/useInterval";
import { useSimpleCollection } from "src/helpers/useSimpleCollection";
import { useToast } from "src/helpers/useToast";

type ImageURI = {
  key: string;
  id: string;
  timestamp: Timestamp;
  uri: string;
  name: string;
  index?: number;
  challenge?: string;
};

type ImageURL = ImageURI & {
  url: string;
};

type Order = "NewestFirst" | "LatestSolutionFirst" | "LatestExtraFirst";

const sortByFirebaseTimestamp = (a: ImageURI, b: ImageURI) =>
  (b?.timestamp?.toMillis() || 0) - (a?.timestamp?.toMillis() || 0);

/* Randomize array in-place using Durstenfeld shuffle algorithm */
const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const Slideshow = () => {
  const { t } = useTranslation("admin");
  const eventId = useEventId();
  const [teams, tLoading] = useSimpleCollection<Team>(`events/${eventId}/teams`);
  const [challenges, cLoading] = useSimpleCollection<Challenge>(`events/${eventId}/challenges`);
  const [currentTeams, setCurrentTeams] = useState<Team[]>([]);
  const [imageUris, setImageUris] = useState<ImageURI[]>([]);
  const [imageURLs, setImageURLs] = useState<ImageURL[]>([]);
  const [event, loading] = useEvent();
  const { toggleFullscreen } = useFullScreen();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const onQrCodeClose = () => setQrCodeOpen(false);
  const [showHighscores, setShowHighscores] = useState(false);

  const cache = useContext(ImageCacheContext);
  const { storage } = useContext(FirebaseContext);

  const pollRetryDownload = async (downloadRef, retries, waitTime = 1500) => {
    try {
      const url = await getDownloadURL(downloadRef);
      return url;
    } catch (e) {
      if (retries <= 1) {
        throw e;
      }
      await new Promise((resolve) => setTimeout(resolve, waitTime));
      return pollRetryDownload(downloadRef, retries - 1);
    }
  };

  const [shuffleEnabled, setShuffleEnabled] = useState(false);
  const toggleShuffle = () => {
    if (shuffleEnabled) toast(t("shuffleDisabled"), "🔀", "error", 4000);
    else toast(t("shuffleEnabled"), "🔀", "success", 4000);

    setShuffleEnabled(!shuffleEnabled);
  };

  useInterval(() => {
    if (shuffleEnabled) {
      const solutionURIs = getSolutionURIs();
      const extrasURIs = getExtrasURIs();
      const allUris = [...solutionURIs, ...extrasURIs];
      const URIsWithIndex = allUris.map((uri, index) => ({ ...uri, index: index + 1 }));
      const newImageURIs = [...URIsWithIndex];
      shuffle(newImageURIs);
      setImageUris(newImageURIs);
    }
  }, 10000);

  const buildImageURLs = async () => {
    const targetNumberOfImages = previewMode === "grid" ? 4 : 1;

    const urls: ImageURL[] = [];
    for (const imageUri of imageUris) {
      const gotEnoughImages = urls.length >= targetNumberOfImages;
      if (gotEnoughImages) break;

      const { uri } = imageUri;
      const lightUri = `${uri}_light`;

      // Check cache first
      const cachedUrl = cache.current.get(lightUri);
      if (cachedUrl) {
        urls.push({ ...imageUri, url: cachedUrl });
        continue;
      }

      // Download fresh
      try {
        const url = await pollRetryDownload(ref(storage, lightUri), 3);
        urls.push({ ...imageUri, url });
      } catch (e) {
        console.log("Error downloading image", e);
      }
    }

    setImageURLs(urls);
  };

  useEffect(() => {
    if (!imageUris.length) return;
    buildImageURLs();
  }, [imageUris]);

  const getSolutionURIs = () =>
    (teams ?? []).flatMap((team) => {
      return Object.keys(team.solutionsMap || {}).map((solution) => {
        const challenge = challenges.find((challenge) => challenge.id === solution);
        return {
          key: `${team.id}${solution}`,
          id: solution,
          timestamp: team.solutionsMap[solution],
          uri: `events/${eventId}/teams/${team.id}/solutions/${solution}`,
          name: `${team.displayName ?? team.name}`,
          challenge: challenge?.title,
        };
      });
    });

  const getExtrasURIs = () =>
    (teams ?? []).flatMap((team) => {
      return Object.keys(team.extrasMap || {}).map((extra) => ({
        key: `${team.id}${extra}`,
        id: extra,
        timestamp: team.extrasMap[extra],
        uri: `events/${eventId}/teams/${team.id}/extras/${extra}`,
        name: `${team.displayName ?? team.name}`,
      }));
    });

  const recomputeURIs = (order?: Order) => {
    const solutionURIs = getSolutionURIs();
    const extrasURIs = getExtrasURIs();
    let recomputedURIs = [];

    if (order === "NewestFirst") {
      const URIs = [...solutionURIs, ...extrasURIs];
      URIs.sort(sortByFirebaseTimestamp);
      recomputedURIs = URIs;
    }

    if (order === "LatestExtraFirst") {
      const sortedExtraURIs = [...extrasURIs];
      sortedExtraURIs.sort(sortByFirebaseTimestamp);
      if (!sortedExtraURIs.length) return [];

      const [latestExtra, ...restOfExtraURIs] = sortedExtraURIs;
      const restOfURIs = [...solutionURIs, ...restOfExtraURIs];
      shuffle(restOfURIs); // Randomise the rest
      recomputedURIs = [latestExtra, ...restOfURIs];
    }

    if (order === "LatestSolutionFirst") {
      const sortedSolutionURIs = [...solutionURIs];
      sortedSolutionURIs.sort(sortByFirebaseTimestamp);
      if (!sortedSolutionURIs.length) return [];

      const [latestSolution, ...restOfSolutionURIs] = sortedSolutionURIs;
      const restOfURIs = [...restOfSolutionURIs, ...extrasURIs];
      shuffle(restOfURIs); // Randomise the rest
      recomputedURIs = [latestSolution, ...restOfURIs];
    }

    const URIsWithIndex = recomputedURIs.map((uri, index) => ({ ...uri, index: index + 1 }));
    return URIsWithIndex;
  };

  const toast = useToast();

  useEffect(() => {
    const notLoadedYet = !teams.length;
    if (notLoadedYet) return;

    const firstLoad = currentTeams.length === 0;
    if (firstLoad) {
      setCurrentTeams(teams);

      const newImageUris = recomputeURIs("NewestFirst");
      setImageUris(newImageUris);
      return;
    }

    const newTeamCreated = teams.length > currentTeams.length;
    if (newTeamCreated) {
      const newTeam = teams.find((team) => !currentTeams.find((currentTeam) => currentTeam.id === team.id));
      toast(`${newTeam.displayName ?? newTeam.name} ${t("newTeamJoined")}`, "🥳", "success", 4000);
      setCurrentTeams([...currentTeams, newTeam]);

      const newImageUris = recomputeURIs("NewestFirst");
      setImageUris(newImageUris);
      return;
    }

    // Check if a new solution (or new extra) has been submitted
    for (const newTeam of teams) {
      const currentTeam = currentTeams.find((currentTeam) => currentTeam.id === newTeam.id);
      if (!currentTeam) continue;

      const newSolution = Object.keys(newTeam.solutionsMap || {}).find(
        (solution) => !Object.keys(currentTeam.solutionsMap || {}).includes(solution),
      );
      if (newSolution) {
        const challenge = challenges.find((challenge) => challenge.id === newSolution);
        if (!challenge) continue;
        toast(`${newTeam.displayName ?? newTeam.name} ${t("completed")} ${challenge.title}`, "🥳", "success", 4000);
        setCurrentTeams(teams);

        const newImageUris = recomputeURIs("LatestSolutionFirst");
        setImageUris(newImageUris);
        forceUpdate();
        break;
      }

      const newExtras = Object.keys(newTeam.extrasMap || {}).length > Object.keys(currentTeam.extrasMap || {}).length;
      if (newExtras) {
        toast(`${newTeam.displayName ?? newTeam.name} ${t("uploadedNewExtras")}`, "📸", "success", 4000);
        setCurrentTeams(teams);

        const newImageUris = recomputeURIs("LatestExtraFirst");
        setImageUris(newImageUris);
        break;
      }
    }
  }, [teams]);

  const [previewMode, setPreviewMode] = useState<"single" | "grid">("grid");
  const togglePreviewMode = () => setPreviewMode(previewMode === "grid" ? "single" : "grid");

  const gridImages = useMemo(() => {
    const images = new Array(4).fill(null).map((_, index) => {
      const image = imageURLs[index];
      if (!image) return null;
      return { ...image, key: `${image.key}${index}` };
    });
    return images;
  }, [imageURLs]);

  const singleImage = useMemo(() => (imageURLs.length ? imageURLs[0] : null), [imageURLs]);

  const { homepage, brandName, brand, slideshowLogoUrl, logoUrl } = useBranding();

  const imagesExist = imageUris.length > 0;

  const [eventLogoUrl, setEventLogoUrl] = useState<string>();

  useEffect(() => {
    if (!eventId) return;

    const fetchEventLogo = async () => {
      try {
        const logoRef = ref(storage, `events/${eventId}/logo.png`);
        const url = await getDownloadURL(logoRef);
        setEventLogoUrl(url);
      } catch (e) {
        // console.log("No event logo found");
      }
    };

    fetchEventLogo();
  }, [eventId, storage]);

  const isCCI = eventId === "cci";

  return (
    <div>
      <div className={`absolute right-5 z-50 hidden text-white md:block`}>
        <a className="ml-6 p-2" onClick={toggleFullscreen}>
          <FullscreenIcon className="h-8 w-8" strokeWidth={1.2} />
        </a>
        <a className="ml-6 p-2" onClick={togglePreviewMode}>
          {previewMode === "grid" ? (
            <ImageIcon className="h-8 w-8" strokeWidth={1.2} />
          ) : (
            <GridIcon className="h-8 w-8" strokeWidth={1.2} />
          )}
        </a>
        <a className="ml-6 p-2" onClick={() => setQrCodeOpen(!qrCodeOpen)}>
          <QRIcon className="h-8 w-8" strokeWidth={1.2} />
        </a>
        <a className="ml-6 p-2" onClick={toggleShuffle}>
          {shuffleEnabled ? (
            <CrossIcon className="h-8 w-8" strokeWidth={1.2} />
          ) : (
            <CycleIcon className="h-8 w-8" strokeWidth={1.2} />
          )}
        </a>
        <a className="ml-6 p-2" onClick={() => setShowHighscores(!showHighscores)}>
          <HighscoresIcon className="h-8 w-8" strokeWidth={1.2} />
        </a>
      </div>
      {!loading && imagesExist && (
        <DraggableEventInfo
          open={qrCodeOpen}
          onClose={onQrCodeClose}
          eventId={eventId}
          pin={event.pin}
          title={t("scanToJoin")}
        />
      )}
      <div>
        <Loading loading={tLoading || cLoading || loading}>
          <div className="absolute top-0 z-40 flex w-full items-end justify-between">
            <a href={homepage} className="ml-6">
              <img src={slideshowLogoUrl} alt={brandName} className="mx-auto ml-4 mt-2 w-64" />
            </a>

            <div className={`mb-6 mt-8 ml-4 flex w-full flex-row justify-start`}>
              <div className={`relative mt-6 ml-4 flex h-5 w-5`}>
                <span
                  className={`absolute mt-2 h-5 w-5 animate-ping rounded-full bg-red-600 opacity-75  ${
                    brand === "tuokis" ? "-mt-4 sm:-mt-12" : ""
                  }`}
                ></span>
                <span
                  className={`relative mt-2 h-5 w-5 rounded-full border border-white border-opacity-100 bg-red-600  ${
                    brand === "tuokis" ? "-mt-4 sm:-mt-12" : ""
                  }`}
                ></span>
              </div>
            </div>
            <div className="md:w-28"></div>
          </div>
          <div className="h-auto w-full">
            <div className="scroll-auto">
              {!loading && !imagesExist && (
                <div className="h-screen w-full bg-neutral text-white">
                  <div className="flex w-full flex-col items-center justify-center pt-16">
                    {isCCI && eventLogoUrl ? (
                      <div className="flex flex-col items-center justify-center">
                        <img src={eventLogoUrl} alt={brandName} className="mx-auto mt-2 w-96" />
                      </div>
                    ) : (
                      <h1 className="mt-16 mb-4 max-w-lg text-center text-5xl font-semibold">{event.name}</h1>
                    )}
                    <EventInfo size="lg" eventId={eventId} pin={event.pin} border white />
                    <h2 className="text-1xl mt-8 mb-4 max-w-lg text-center font-normal">{t("scanToJoin")}</h2>
                  </div>
                </div>
              )}

              {imagesExist && (
                <>
                  <div className="absolute z-10 h-full w-full bg-black object-cover opacity-90 blur" />
                  {previewMode === "grid" && (
                    <div className="z-20 grid h-screen grid-cols-2 grid-rows-2 border-4 border-black ">
                      {gridImages.map((image, index) => {
                        const lastImage = index === 3;
                        if (lastImage && showHighscores)
                          return (
                            <div className="z-10 overflow-auto border-4 border-black bg-base-100">
                              <div className="p-6">
                                <h2 className="mb-4 text-center text-3xl font-semibold text-neutral">
                                  {t("highscores")}
                                </h2>
                                <HighscoreTable compact key="highscores" />
                              </div>
                            </div>
                          );

                        if (!image) return <div className="z-10 overflow-auto border-4 border-black bg-neutral" />;
                        const { url, name, key, challenge } = image;
                        return (
                          <div className="relative z-10 overflow-hidden border-4 border-black" key={key}>
                            <InstantImage
                              className="z-30 h-full w-full animate-slow-fade-in object-cover opacity-70 blur"
                              url={url}
                            />
                            <div className="z-20 flex w-full  justify-center">
                              <InstantImage className="absolute	top-0 h-full animate-fade-in object-contain" url={url} />
                            </div>
                            <h2 className="absolute bottom-0 mb-4 w-full text-center text-white">
                              <div className="text-xl font-semibold">{name}</div>
                              {challenge && <div className="text-sm">{challenge}</div>}
                            </h2>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {previewMode === "single" && (
                    <div className="absolute top-0 h-full w-full border-4 border-black">
                      <div className="relative z-30 h-full w-full overflow-hidden border-4 border-black">
                        {singleImage && (
                          <>
                            <InstantImage
                              key={singleImage.key}
                              className="z-30 h-full w-full animate-slow-fade-in object-cover opacity-70 blur"
                              url={singleImage.url}
                            />
                            <div className="flex w-full animate-slow-fade-in justify-center">
                              <InstantImage
                                key={singleImage.key}
                                className="absolute top-0 h-full animate-fade-in object-contain"
                                url={singleImage.url}
                              />
                            </div>
                          </>
                        )}
                        {showHighscores ? (
                          <div className="absolute top-0 flex h-full w-full justify-center overflow-auto bg-base-300 opacity-95">
                            <div className="mt-24 h-full w-full max-w-2xl p-12">
                              <h2 className="mb-4 text-center text-3xl font-semibold text-neutral">
                                {t("highscores")}
                              </h2>
                              <HighscoreTable limit={25} />
                            </div>
                          </div>
                        ) : (
                          <h2 className="absolute bottom-0 mb-12 w-full text-center text-white">
                            <div className="text-4xl font-semibold">{singleImage.name}</div>
                            {singleImage.challenge && <div className="text-xl">{singleImage.challenge}</div>}
                          </h2>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Loading>
      </div>
      {eventLogoUrl && (
        <div className="fixed bottom-8 left-8 z-50">
          <img src={eventLogoUrl} alt="Event logo" className="max-w-64 max-h-32 object-contain" />
        </div>
      )}
    </div>
  );
};
