import { FC, useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";

export type Props = {
  defaultValue?: string;
  placeholder?: string;
  startingImageUrl: string;
  onNewImage: (image: Blob) => void;
  onNewCaption?: (caption: string) => void;
  onTimeout?: () => void;
  render: boolean;
};

export const ImageOverlay: FC<Props> = ({
  defaultValue,
  placeholder,
  render,
  startingImageUrl,
  onNewImage,
  onNewCaption,
  onTimeout,
}) => {
  const [caption, setCaption] = useState(defaultValue ?? "");
  const componentRef = useRef(null);
  const [rendering, setRendering] = useState(false);

  const handleCaptionChange = (event) => {
    const caption = event.target.value;
    setCaption(caption);
    onNewCaption(caption);
  };

  const renderImage = async () => {
    setRendering(true);
    await new Promise((resolve) => setTimeout(resolve, 100));

    const timeoutHandler = setTimeout(() => onTimeout && onTimeout(), 12000);
    const canvas = await html2canvas(componentRef.current, { imageTimeout: 60000, useCORS: true });
    clearTimeout(timeoutHandler);

    canvas.setAttribute("willReadFrequently", "true");
    canvas.toBlob((blob) => {
      onNewImage(blob);
      setRendering(false);
    });
  };

  useEffect(() => {
    if (render) renderImage();
  }, [render]);

  if (!startingImageUrl) return null;

  return (
    <div className="relative h-auto w-full" ref={componentRef}>
      <img crossOrigin="anonymous" src={startingImageUrl} alt="Example" className="h-full w-full object-cover" />
      <div className="absolute inset-0 flex flex-col items-center justify-end bg-black bg-opacity-75">
        {rendering && (
          <div className="textarea-overlay text-md flex h-full resize-none overflow-hidden whitespace-pre-line break-words bg-transparent bg-opacity-75 p-4 text-center font-bold text-white">
            {caption}
          </div>
        )}
        {!rendering && (
          <textarea
            id="caption"
            aria-label="Image caption"
            placeholder={placeholder}
            value={caption}
            onChange={handleCaptionChange}
            className="textarea-overlay text-md h-full w-full resize-none overflow-hidden break-words bg-transparent p-4 text-center font-bold text-white focus:outline-none"
            style={{ caretColor: "white", whiteSpace: "pre-wrap" }}
          />
        )}
      </div>
    </div>
  );
};
