import { Footer } from "@components/Footer";
import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { useSimpleCollection } from "src/helpers/useSimpleCollection";
import { useTeamId } from "src/helpers/useTeam";
import { Link } from "@components/Link";
import { DoneIcon } from "@components/Icons/Done";
import { usePersistedState } from "src/helpers/usePersistedState";
import { PremiumModal } from "@components/PremiumModal/PremiumModal";
import { useTranslatedKey } from "@helpers/useTranslatedKey";
import { create } from "zustand";
import { SpinnerIcon } from "@components/Icons/Spinner";

type ChallengeId = string;
export type Challlenges = Map<ChallengeId, boolean>;

export const useProgressStore = create((set) => ({
  challenges: {} as Challenges,
  setChallenges: (challenges: ChallengeId[]) => {
    set((state) => {
      const newChallenges = challenges.reduce(
        (acc, challengeId) => {
          if (!(challengeId in state.challenges)) {
            acc[challengeId] = false;
          } else {
            acc[challengeId] = state.challenges[challengeId];
          }
          return acc;
        },
        { ...state.challenges },
      );
      return { challenges: newChallenges };
    });
  },
  setChallenge: (challengeId: ChallengeId, loading: boolean) => {
    set((state) => ({
      challenges: {
        ...state.challenges,
        [challengeId]: loading,
      },
    }));
  },
}));

type ChallengeWithCompleted = Challenge & { completed: boolean };

export const Challenges: FC = () => {
  const { t } = useTranslation("challenges");

  const [appData, setAppData] = usePersistedState();
  const eventId = useEventId();
  const [event] = useEvent();
  const teamId = useTeamId();

  const [challenges] = useSimpleCollection<Challenge>(`events/${eventId}/challenges`);
  const [solutions] = useSimpleCollection<Solution>(`events/${eventId}/teams/${teamId}/solutions`);

  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const navigate = useEventNavigate();

  // @ts-ignore
  const setChallengeLoadingStates = useProgressStore((state) => state.setChallenges);
  // @ts-ignore
  const challengeLoadingStates = useProgressStore((state) => state.challenges);

  const [challengesWithSolutions, setChallengesWithSolutions] = useState<ChallengeWithCompleted[]>([]);
  useEffect(() => {
    const challengesWithSolutions = challenges?.map((challenge) => {
      const solution = solutions?.find((solution) => solution.id === challenge.id);
      return { ...challenge, completed: !!solution?.completed };
    });
    setChallengesWithSolutions(challengesWithSolutions);

    const challengeIds = challengesWithSolutions.map((challenge) => challenge.id);
    setChallengeLoadingStates(challengeIds);
  }, [challenges, solutions]);

  // Restore scroll position the first time user navigates to this page
  // Scroll position is captured in the location state when user clicks on a challenge
  useEffect(() => {
    const hasScrollPosition = !!appData?.persistedScrollPosition;
    const hasChallenges = challengesWithSolutions?.length > 0;
    if (!hasScrollPosition || !hasChallenges) return;

    window.scrollTo(0, appData.persistedScrollPosition);
    setAppData({ ...appData, persistedScrollPosition: undefined });
  }, [challengesWithSolutions]);

  useEffect(() => {
    window.onbeforeunload = () => {
      setAppData({ ...appData, persistedScrollPosition: window?.scrollY });
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  if (!challenges || !solutions) return null;

  const filterHiddenChallenges = (challenge: Challenge) => !challenge.hidden;
  const orderedChallenges = useMemo(() => {
    if (!event || !challengesWithSolutions) return [];
    let ordered = challengesWithSolutions;
    if (event.order) {
      ordered = event.order.map((id) => challengesWithSolutions?.find((challenge) => challenge.id === id));

      // also find any that are not in the order and add them to the end
      const notOrdered = challengesWithSolutions?.filter((challenge) => !event.order.includes(challenge.id));
      ordered = [...ordered, ...notOrdered];
    }
    return ordered?.filter((challenge) => !!challenge).filter(filterHiddenChallenges);
  }, [event, challengesWithSolutions]);

  const persistScrollPosition = () => {
    setAppData({ ...appData, persistedScrollPosition: window?.scrollY });
  };

  const onChallengeClick = (challenge: Challenge & Solution) => {
    persistScrollPosition();
    navigate(`/challenges/${challenge.id}`);
  };

  const { getTranslatedValue } = useTranslatedKey();

  const isTrial = !!event?.isTrial;

  const [showCompletionOverlay, setShowCompletionOverlay] = useState(false);

  useMemo(() => {
    const completed = orderedChallenges?.length > 0 && orderedChallenges.every((challenge) => challenge.completed);
    if (completed) setShowCompletionOverlay(true);
  }, [orderedChallenges]);

  const handleOverlayClick = () => {
    setShowCompletionOverlay(false);
  };

  return (
    <div className="h-screen">
      <main>
        <TopNav isAdmin={false} />
        {showPremiumModal && <PremiumModal show={showPremiumModal} onClose={() => setShowPremiumModal(false)} />}
        <Loading loading={!challenges || !solutions}>
          {showCompletionOverlay && (
            <div
              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
              onClick={handleOverlayClick}
            >
              <div className="mx-8 flex flex-col rounded-xl bg-base-100 p-8 text-center text-2xl font-bold">
                <div>{t("congratulationsAllCompleted")}</div>
                <div className="mt-8 text-center text-xs font-extralight text-neutral">{t("clickAnywhereToClose")}</div>
              </div>
            </div>
          )}
          <div className="flex justify-center ">
            <h1 className={"mt-16 mb-12 rounded-xl px-4 text-3xl font-bold"}>{t("title")}</h1>
          </div>
          <div className="mt-4 mb-8 flex w-full flex-col items-center">
            <div className="w-full px-6 md:px-32 xl:px-64 xl:px-96">
              {orderedChallenges.map((challenge, index) => {
                const { id, points, completed } = challenge;
                const title = getTranslatedValue(challenge, "title");
                const about = getTranslatedValue(challenge, "about");

                const trialDegrade = isTrial && index > 2; // blur all but the first few challenges if it's a trial event
                const isUploading = challengeLoadingStates[id];
                const handleChallengeClick = () => {
                  if (isUploading || trialDegrade) return;
                  return onChallengeClick(challenge);
                };

                return (
                  <div key={id} className="mb-6" role="button" onClick={handleChallengeClick}>
                    <div className={"rounded-2xl bg-base-200 bg-opacity-90"}>
                      {trialDegrade && (
                        <div className="flex justify-center">
                          <div className="z-100 absolute mt-12 text-center ">
                            <span className="p-4 text-center font-black text-black">{t("upgradeToUnlock")}</span>
                          </div>
                        </div>
                      )}
                      <div
                        className={`card w-full border border-base-300 ${
                          completed ? " bg-success bg-opacity-40" : "bg-base-200"
                        } ${trialDegrade ? "bg-opacity-40 blur" : ""} shadow-xl ${
                          isUploading ? "animate-pulse opacity-50" : ""
                        }`}
                      >
                        <div className="flex justify-between">
                          <div className="w-full">
                            <div className="card-body">
                              {completed && (
                                <div className="-m-1 flex justify-center">
                                  <div className="z-100 absolute text-center text-gray-100">
                                    <DoneIcon className="h-16 w-16" />
                                  </div>
                                </div>
                              )}

                              {isUploading && (
                                <div className="-mt-1 flex justify-center">
                                  <div className="z-100 absolute text-center text-gray-100">
                                    <SpinnerIcon className="h-16 w-16" />
                                  </div>
                                </div>
                              )}

                              <div className={`${completed || trialDegrade ? "blur-xs" : ""}`}>
                                <div className="flex justify-between">
                                  <h2 className="card-title">{title}</h2>
                                  <div className="text-sm font-semibold">{points}</div>
                                </div>
                                <p className="min-h-6">{about}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Link to="upload">
            <div className="flex justify-center">
              <div className={`mt-4 w-full pb-12 text-center text-sm font-bold text-neutral underline`}>
                <span>{t("uploadPageHint")}</span>
              </div>
            </div>
          </Link>
        </Loading>
      </main>
      <Footer />
    </div>
  );
};
