import { FC, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import "photoswipe/style.css";
import { Image, Swiper } from "./Swiper";
import { CheckIcon } from "@components/Icons/Check";
import { OverlaySpinner } from "@components/Loading/OverlaySpinner";

type Props = {
  challenges: Challenge[];
  teams: Team[];
  eventName: string;
  eventId: string;
  allowDownload: boolean;
  allowDelete?: boolean;
};

export const Pictures: FC<Props> = ({ eventName, allowDelete, challenges, teams, eventId, allowDownload }) => {
  const [teamsSelected, setTeamsSelected] = useState<Team[]>(teams);
  const { t } = useTranslation("admin");
  const [downloading, setDownloading] = useState(false);

  const toggleTeam = (team: Team) => {
    if (allSelected()) {
      setTeamsSelected([team]);
      return;
    }

    if (teamsSelected.includes(team)) {
      const newTeams = teamsSelected.filter((t) => t.id !== team.id);
      setTeamsSelected(newTeams);
    } else {
      const newTeams = [team, ...teamsSelected];
      setTeamsSelected(newTeams);
    }
  };

  const isSelected = (team: Team) => {
    return teamsSelected.find((t) => t.id === team.id);
  };

  const allSelected = () => {
    return teamsSelected.length === teams.length;
  };

  const toggleAll = () => {
    if (allSelected()) {
      setTeamsSelected([]);
    } else {
      setTeamsSelected(teams);
    }
  };

  const totalShowingPhotos = teams.reduce((total, team) => {
    if (!isSelected(team)) return total;
    const teamPhotos = Object.keys(team.solutionsMap || {}).length;
    const teamExtras = Object.keys(team.extrasMap || {}).length;
    return total + teamPhotos + teamExtras;
  }, 0);

  const totalAllPhotos = teams.reduce((total, team) => {
    const teamPhotos = Object.keys(team.solutionsMap || {}).length;
    const teamExtras = Object.keys(team.extrasMap || {}).length;
    return total + teamPhotos + teamExtras;
  }, 0);

  const images: Image[] = teams.reduce((images, team) => {
    if (!isSelected(team)) return images;
    const solutions = Object.keys(team.solutionsMap || {});
    const extras = Object.keys(team.extrasMap || {});

    const solutionImages: Image[] = solutions
      .filter((solution) => !solution.endsWith("-video"))
      .map((solution) => {
        const hasVideo =
          solutions.findIndex((s) => s.endsWith("-video") && s.startsWith(solution.substring(0, 8))) > -1;
        return {
          uri: `events/${eventId}/teams/${team.id}/solutions/${solution}`,
          filename: `${team.name}-${solution.substring(0, 8)}.jpg`,
          team: team.displayName ?? team.name,
          challenge: challenges.find((c) => c.id === solution)?.title || "",
          hasVideo,
        };
      });
    const extrasImages: Image[] = extras.map((extra) => {
      return {
        uri: `events/${eventId}/teams/${team.id}/extras/${extra}`,
        filename: `${team.name}-${extra.substring(0, 8)}.jpg`,
        team: team.displayName ?? team.name,
      };
    });
    const teamImages = [...solutionImages, ...extrasImages];
    return [...images, ...teamImages];
  }, []);

  return (
    <>
      <OverlaySpinner loading={downloading} />
      <div>
        <div className="flex flex-wrap items-center justify-center pt-8 pb-4 md:py-8">
          <button
            onClick={toggleAll}
            type="button"
            className={`mx-1 mt-2 inline-flex items-center rounded-full bg-gray-50 px-3 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ${
              allSelected() ? "bg-base-200 font-bold ring-base-300" : "bg-base-100 font-light text-opacity-40"
            }`}
          >
            {t("allTeams")} {allSelected() ? <CheckIcon className="ml-2 h-4 w-4" /> : null}
          </button>
          {teams.map((team) => (
            <span
              key={team.id}
              onClick={() => toggleTeam(team)}
              className={`mx-1 mt-2 inline-flex items-center rounded-full bg-gray-50 px-3 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ${
                isSelected(team) ? "bg-base-200 font-bold ring-base-300" : "bg-base-100 font-light text-opacity-40"
              }`}
            >
              {team.displayName ?? team.name} {isSelected(team) ? <CheckIcon className="ml-2 h-4 w-4" /> : null}
            </span>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center py-4 text-sm font-semibold uppercase text-neutral md:py-8">
          {`${t("showing")} ${totalShowingPhotos} ${t("from")} ${totalAllPhotos} ${t("totalPhotos")}`}
        </div>
        <div className="pb-24 xl:px-32">
          <Swiper
            allowDelete={allowDelete}
            allowDownload={allowDownload}
            defaultEventId={eventId}
            eventName={eventName}
            images={images}
            setDownloading={setDownloading}
          />
        </div>
      </div>
    </>
  );
};
