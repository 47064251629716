import { TeamRow } from "@components/HighscoreTable/TeamRow";
import { useEventId } from "@helpers/useEvent";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PreviewFooter } from "./PreviewFooter";
import { PreviewTopBar } from "./PreviewTopBar";

type Props = {
  goToChallenges: () => void;
  goToUpload: () => void;
};

export const PreviewHighscores: FC<Props> = ({ goToChallenges, goToUpload }) => {
  const { t } = useTranslation("highscores");
  const eventId = useEventId();
  const teams: Team[] = [
    {
      id: "colleagues",
      name: t("colleagues"),
      points: 420,
    },
    {
      name: t("friends"),
      points: 320,
    },
    {
      name: t("family"),
      points: 220,
    },
    {
      name: t("others"),
      points: 120,
    },
  ] as Team[];

  const thisTeamId = "colleagues";

  return (
    <div>
      <PreviewTopBar goBack={goToChallenges} />
      <h1 className="mb-10 mt-16 text-center text-3xl font-semibold">{t("title")}</h1>
      <div className={`highscore-table px-10 py-4`}>
        <div className="tooltip-extra-tight tooltip" data-tip={t("previewCopy")}>
          <table className="table w-full">
            <thead>
              <tr>
                <th className="pl-4 pr-2">#</th>
                <th className="pr-2 pl-2">{t("team")}</th>
                <th className="pr-2 pl-2"></th>
                <th className="pr-4 pl-2">{t("score")}</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team, index) => (
                <>
                  {team.id === thisTeamId && (
                    <div className="absolute left-0 mt-5 -ml-5 animate-bounce-horizontal-inertia text-neutral">❯</div>
                  )}
                  <TeamRow
                    active={team.id === thisTeamId}
                    key={team.id}
                    index={index + 1}
                    teamKey={team.id}
                    teamName={team.displayName ?? team.name}
                    points={Math.round(team?.points)}
                    eventId={eventId}
                  />
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <PreviewFooter
        goToChallenges={goToChallenges}
        goToHighscores={() => {}}
        goToUpload={goToUpload}
        activePage={"highscores"}
      />
    </div>
  );
};
