import { DateInput } from "@components/DateInput";
import { FullPicker } from "@components/ImagePicker/FullPicker";
import { Input } from "@components/Input";
import { Textarea } from "@components/Input/Textarea";
import { TopNav } from "@components/TopNav";
import { useTranslation } from "@helpers/useTranslation";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { FieldValues, useForm, UseFormSetValue } from "react-hook-form";
import { FirebaseContext } from "src/helpers/firebase";
import { useBranding } from "src/helpers/useBranding";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { useToast } from "src/helpers/useToast";
import { Toggle } from "@components/Toggle";
import { ConfirmModal } from "@components/ConfirmModal";

const eventTypes = ["wedding", "birthday", "other"];

export const Customise = () => {
  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const handleShowPicker = () => dateInputRef?.current?.showPicker && dateInputRef.current.showPicker();
  const [saving, setSaving] = useState(false);

  const { firestore, storage } = useContext(FirebaseContext);
  const { t } = useTranslation("admin");
  const navigate = useEventNavigate();
  const [event] = useEvent();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<{
    name: string;
    pin: string | number;
    about: string;
    type: string;
    locales: { en: boolean; lt: boolean };
    pointDegrade: boolean;
  }>({
    defaultValues: useMemo(
      () => ({
        name: event?.name ?? "",
        pin: event?.pin ?? "",
        about: event?.about ?? "",
        type: event?.type ?? eventTypes[0],
        locales: {
          en: event?.locales.en ?? false,
          lt: event?.locales.lt ?? false,
        },
        pointDegrade: event?.pointDegrade ?? false,
      }),
      [event],
    ),
  });

  useEffect(() => {
    reset(event);
  }, [event]);

  const eventId = useEventId();

  const [image, setImage] = useState(undefined);
  const [logo, setLogo] = useState(undefined);
  const [activeTab, setActiveTab] = useState<number>(1);

  //https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const toast = useToast();

  const { appDomain } = useBranding();

  const [showDegradeConfirm, setShowDegradeConfirm] = useState(false);

  const onSubmit = async ({ locales, name, pin, type, about, date, pointDegrade }) => {
    setSaving(true);
    if (image) {
      const imagePath = `events/${eventId}/background`;
      const imageRef = storageRef(storage, imagePath);
      await uploadBytes(imageRef, image);
    }

    if (logo) {
      const logoPath = `events/${eventId}/logo.png`;
      const logoRef = storageRef(storage, logoPath);
      await uploadBytes(logoRef, logo);
    }

    try {
      const docRef = doc(firestore, `events/${eventId}`);
      await setDoc(
        docRef,
        {
          locales,
          name,
          pin,
          type,
          about,
          date,
          pointDegrade: pointDegrade ?? false,
          lastModifiedOn: Timestamp.now(),
        },
        { merge: true },
      );
    } catch (error) {
      console.error(error);
    }

    setSaving(false);
    toast(t("updated"), "👍", "success", 1500);
    navigate("/admin");
  };

  const values = getValues();
  const ltLocale = values.locales?.lt;
  const enLocale = values.locales?.en;

  if (!event) return null;
  return (
    <div className="flex flex-col">
      <TopNav backLink="/admin" isAdmin />
      <main className="flex-1 flex-col">
        <div className="flex flex-col px-6 py-4">
          <h1 className="mt-12 mb-4 text-center text-3xl font-semibold">{t("customise")}</h1>
          <h4 className="pt-4 text-center text-sm font-semibold text-neutral">{`${appDomain}/${eventId}`}</h4>
          <div className="flex justify-center">
            <p className="mt-12 max-w-lg text-center font-light">{t("customiseAbout")}</p>
          </div>
          <div className="mt-12 flex w-full flex-col items-center px-4">
            <form onSubmit={handleSubmit(onSubmit)} className="h-100 flex w-full max-w-lg flex-col items-center py-4">
              <div className="tabs mb-8 mt-8 w-full">
                <a
                  className={`tab-bordered tab ${activeTab === 1 ? "tab-active" : ""} w-1/3`}
                  onClick={() => setActiveTab(1)}
                >
                  {t("details")}
                </a>
                <a
                  className={`tab-bordered tab ${activeTab === 2 ? "tab-active" : ""} w-1/3`}
                  onClick={() => setActiveTab(2)}
                >
                  {t("backgroundPhoto")}
                </a>
                <a
                  className={`tab-bordered tab ${activeTab === 3 ? "tab-active" : ""} w-1/3`}
                  onClick={() => setActiveTab(3)}
                >
                  {t("logo")}
                </a>
              </div>
              <div className={`w-full ${!activeTab || activeTab === 1 ? "" : "hidden"}`}>
                <div className="align-start mb-2 flex flex-row" onClick={() => handleShowPicker()}>
                  <DateInput
                    ref={dateInputRef}
                    label={t("dateField")}
                    name="date"
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    label={t("eventName")}
                    name="name"
                    placeholder={t("eventNameExample")}
                    required
                    requiredMessage={t("fieldRequired")}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    label={t("pinCode")}
                    name="pin"
                    placeholder={t("pinCodeExample")}
                    pattern="^\d{4}$"
                    title={t("pinCodeValidationError")}
                    required
                    requiredMessage={t("fieldRequired")}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="mb-4">
                  <Textarea
                    placeholder={t("aboutPlaceholder")}
                    label={t("introAbout")}
                    name="about"
                    rows={2}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="mb-12">
                  <div className="mb-4 flex w-full flex-col">
                    <label className="label">
                      <span className="label-text">
                        {t("eventType")}
                        <span className="text-warning">{` *`}</span>
                      </span>
                    </label>
                    <select
                      className="select-bordered select"
                      defaultValue={eventTypes[0]}
                      required
                      {...register("type", { required: true })}
                    >
                      {eventTypes.map((type) => (
                        <option value={type}>{t(type)}</option>
                      ))}
                      {errors["type"] && <span className="pl-1 pt-1 text-xs text-warning">{t("requiredMessage")}</span>}
                    </select>
                  </div>
                </div>
                <h2 className="mt-8 mb-4">{t("scoring")}</h2>
                <Toggle
                  label={t("degradePoints")}
                  className="flex flex-col"
                  name="pointDegrade"
                  errors={errors}
                  getValues={getValues as any}
                  setValue={() => {}}
                  onClick={() => {
                    const toggledOn = !getValues("pointDegrade");
                    if (toggledOn) setShowDegradeConfirm(true);
                    else setValue("pointDegrade", false);
                    forceUpdate();
                  }}
                />
                <ConfirmModal
                  show={showDegradeConfirm}
                  onCancel={() => {
                    setShowDegradeConfirm(false);
                  }}
                  onConfirm={() => {
                    setValue("pointDegrade", true);
                  }}
                  type="success"
                  messageCopy={t("degradePointsConfirm")}
                  actionCopy={t("enable")}
                />
                <h2 className="mt-8 mb-4">{t("supportedLanguages")}</h2>
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <span className="label-text">{t("english")}</span>
                    <input
                      type="checkbox"
                      checked={enLocale}
                      className={`checkbox ${enLocale ? "checkbox-primary" : ""}`}
                      onClick={() => {
                        if (!ltLocale && enLocale) setValue("locales.lt", true); // prevent disabling both languages
                        setValue("locales.en", !enLocale);
                        forceUpdate();
                      }}
                    />
                  </label>
                </div>
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <span className="label-text">{t("lithuanian")}</span>
                    <input
                      type="checkbox"
                      className={`checkbox ${ltLocale ? "checkbox-primary" : ""}`}
                      checked={ltLocale}
                      onClick={() => {
                        if (!enLocale && ltLocale) setValue("locales.en", true); // prevent disabling both languages
                        setValue("locales.lt", !ltLocale);
                        forceUpdate();
                      }}
                    />
                  </label>
                </div>
                <div className="form-control">
                  <div className="tooltip tooltip-info" data-tip={t("comingSoon")}>
                    <label className="label cursor-pointer">
                      <span className="label-text">{t("russian")}</span>
                      <input disabled type="checkbox" className="checkbox" />
                    </label>
                  </div>
                </div>

                <div className="form-control">
                  <div className="tooltip tooltip-info" data-tip={t("comingSoon")}>
                    <label className="label cursor-pointer">
                      <span className="label-text">{t("spanish")}</span>
                      <input disabled type="checkbox" className="checkbox" />
                    </label>
                  </div>
                </div>
              </div>
              <div className={`${activeTab === 2 ? "" : "hidden"}`}>
                <div className="text-center text-sm font-light">{t("backgroundPhotoAbout")}</div>
                <FullPicker
                  defaultImageUri={`events/${eventId}/background_light`}
                  getFreshDefault={true}
                  uploadLabel={t("upload")}
                  reuploadLabel={t("reupload")}
                  onNewImage={setImage}
                  allowUpload
                  imageType="background"
                />
              </div>
              <div className={`${activeTab === 3 ? "" : "hidden"}`}>
                <div className="text-center text-sm font-light">{t("logoUploadAbout")}</div>
                <FullPicker
                  defaultImageUri={`events/${eventId}/logo.png`}
                  getFreshDefault={true}
                  uploadLabel={t("upload")}
                  reuploadLabel={t("reupload")}
                  onNewImage={setLogo}
                  allowUpload
                  imageType="logo"
                />
              </div>
              <div className="mb-12 mt-8 flex justify-center">
                <button
                  type="submit"
                  disabled={saving}
                  className={`inline-flex w-full justify-center rounded-md bg-primary px-8 py-3 text-sm font-semibold text-white shadow-sm hover:bg-base-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                    saving ? "loading" : ""
                  }`}
                  id="submit-button"
                >
                  {t("save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};
