import { Input } from "@components/Input";
import { Textarea } from "@components/Input/Textarea";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { FC, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useToast } from "src/helpers/useToast";
import { Question } from "./Question";
import { Modal } from "@components/Modal";
import { ConfirmModal } from "@components/ConfirmModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslatedKey } from "@helpers/useTranslatedKey";
import { reorder } from "@helpers/reorder";
import { uid } from "@helpers/uid";
import { TrialDegrate } from "@components/TrialDegrate";
import { useSimpleCollection } from "@helpers/useSimpleCollection";

type Props = {
  close: () => void;
};

export const CreateModal: FC<Props> = ({ close }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);

  const { firestore } = useContext(FirebaseContext);
  const eventId = useEventId();
  const [updating, setUpdating] = useState(false);
  const toast = useToast();

  //https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [event] = useEvent();

  const { getKeyName } = useTranslatedKey();

  const onSubmit = async ({ reward, type, title, about, copy, questions, showCorrectAnswers }) => {
    showCorrectAnswers = !!showCorrectAnswers;
    setUpdating(true);
    try {
      questions = (questions || []).map((question) => ({
        ...question,
        [getKeyName("question")]: question.question,
        [getKeyName("answer0")]: question.answer0,
        [getKeyName("answer1")]: question.answer1,
        [getKeyName("answer2")]: question.answer2,
        [getKeyName("answer3")]: question.answer3,
      }));

      const payload = { reward: parseInt(reward), type, questions, title, about, copy, showCorrectAnswers };
      payload[getKeyName("title")] = title;
      payload[getKeyName("about")] = about;
      payload[getKeyName("copy")] = copy;

      const col = collection(firestore, `events/${eventId}/challenges`);
      const { id } = await addDoc(col, payload);
      const docRef = doc(firestore, `events/${eventId}`);
      const currentOrder = event?.order || [];
      await updateDoc(docRef, { order: [...currentOrder, id] });
    } catch (error) {
      console.error(error);
    }
    setUpdating(false);
    toast(t("created"), "👍");
    close();
  };

  const questions: QuizQuestion[] = getValues("questions") || [];
  const challengeType = watch("type");
  const onNewQuestionClick = () => {
    if (isTrial) return;
    const newQuestions = [
      ...questions,
      { id: uid(), question: "", answer0: "", answer1: "", answer2: "", answer3: "", correctAnswer: 0 },
    ];
    setValue("questions", newQuestions);
    forceUpdate();
  };

  const isQuiz = useMemo(() => challengeType === "quiz", [challengeType]);
  useEffect(() => {
    if (isQuiz) setActiveTab(1);
  }, [challengeType]);

  const isPhoto = useMemo(() => challengeType === "photo" || challengeType == "video", [challengeType]);
  useEffect(() => {
    if (isPhoto) setActiveTab(undefined);
  }, [isPhoto]);

  const isGreeting = useMemo(() => challengeType === "greeting", [challengeType]);
  const isVideo = useMemo(() => challengeType === "video", [challengeType]);
  useEffect(() => {
    if (isGreeting) {
      setValue("title", t("greeting"));
      setValue("about", t("greetingDefaultCopy"));
    }
    if (isPhoto || isQuiz || isVideo) {
      setValue("title", "");
      setValue("about", "");
    }
  }, [isGreeting, isPhoto, isVideo, isQuiz]);

  const [activeQuestionToRemove, setActiveQuestionToRemove] = useState<number | undefined>(undefined);
  const onRemove = (index: number) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setValue("questions", newQuestions);
    forceUpdate();
  };

  const moveItem = (index: number, direction: "up" | "down") => {
    if (direction === "up" && index > 0) {
      const newOrder = reorder(questions, index, index - 1);
      setValue("questions", newOrder);
    } else if (direction === "down" && index < questions.length - 1) {
      const newOrder = reorder(questions, index, index + 1);
      setValue("questions", newOrder);
    }
    forceUpdate();
  };

  const [challenges] = useSimpleCollection<Challenge>(`events/${eventId}/challenges`);
  const greetingChallenges = useMemo(() => challenges.filter((c) => c.type === "greeting"), [challenges]);

  const challengeTypes = useMemo(() => {
    const types = ["photo", "quiz", "video"];

    const isNotCorporateEvent = !event?.isPromo;
    const noGreetingYet = greetingChallenges.length === 0; // we only allow one greeting challenge per event
    if (isNotCorporateEvent && noGreetingYet) types.push("greeting");

    return types;
  }, [event]);

  const isTrial = event?.isTrial || false;
  const blurChallenge = challengeType === "video" && isTrial;

  return (
    <>
      <ConfirmModal
        show={activeQuestionToRemove !== undefined}
        onCancel={() => setActiveQuestionToRemove(undefined)}
        onConfirm={() => {
          if (activeQuestionToRemove !== undefined) onRemove(activeQuestionToRemove);
        }}
      />
      <Modal show={true} onCancel={close}>
        <h3 className="text-lg font-bold">{t("create")}</h3>
        <form onSubmit={handleSubmit(onSubmit)} className="h-100 pt-4">
          <div className="mb-4 flex flex-col">
            <label className="label">
              <span className="label-text">{t("challengeType")}</span>
            </label>
            <select
              className="select-bordered select"
              defaultValue={challengeTypes[0]}
              {...register("type", { required: true })}
            >
              {challengeTypes.map((type) => (
                <option value={type}>{t(type)}</option>
              ))}
            </select>
          </div>
          <TrialDegrate degrade={blurChallenge}>
            <div className={`${isQuiz ? "" : "hidden"}`}>
              <div className="tabs mb-4 mt-8">
                <a
                  className={`tab-bordered tab ${activeTab && activeTab === 1 ? "tab-active" : ""} w-[50%]`}
                  onClick={() => setActiveTab(1)}
                >
                  {t("details")}
                </a>
                <a
                  className={`tab-bordered tab ${activeTab && activeTab === 2 ? "tab-active" : ""} w-[50%]`}
                  onClick={() => setActiveTab(2)}
                >
                  {t("questions")}
                </a>
              </div>
            </div>
            <div className={`${!activeTab || activeTab === 1 ? "" : "hidden"}`}>
              <Input
                label={t("challenge")}
                name="title"
                required
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                className="max-w-lg"
              />
              <span className="mt-2" />
              <Textarea
                label={t("about")}
                name="about"
                rows={2}
                errors={errors}
                register={register}
                className="max-w-lg"
              />
              {!isGreeting && (
                <Textarea
                  label={t("copy")}
                  name="copy"
                  required
                  rows={4}
                  requiredMessage={t("fieldRequired")}
                  errors={errors}
                  register={register}
                  className="max-w-lg"
                />
              )}
              <Input
                label={t("reward")}
                type="number"
                name="reward"
                required
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                className="max-w-lg"
              />
              {challengeType === "quiz" && (
                <div className="form-control mt-4 w-full">
                  <label className="label cursor-pointer">
                    <span className="label-text mr-3 text-sm">{t("showCorrectAnswers")}</span>
                    <input
                      type="checkbox"
                      className="toggle-primary toggle"
                      defaultChecked={getValues(`showCorrectAnswers`)}
                      onClick={(e) => {
                        const value = (e.target as any).checked;
                        setValue(`showCorrectAnswers`, value);
                        forceUpdate();
                      }}
                    />
                  </label>
                </div>
              )}
            </div>
            <div className={`${activeTab === 2 ? "" : "hidden"}`}>
              <DragDropContext onDragEnd={() => {}}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {questions.map(({ question, id }, i) => {
                        const key = id ?? question ?? i;
                        return (
                          <Draggable key={key} draggableId={key} index={i}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps}>
                                <div {...provided.dragHandleProps}>
                                  <Question
                                    moveItem={moveItem}
                                    isFirst={i === 0}
                                    isLast={i === questions.length - 1}
                                    key={question}
                                    updateCorrectAnswer={(correctAnswer) => {
                                      setValue(`questions.${i}.correctAnswer`, correctAnswer);
                                      forceUpdate();
                                    }}
                                    questionIndex={i}
                                    correctAnswer={getValues(`questions.${i}.correctAnswer`)}
                                    register={register}
                                    errors={errors}
                                    setActiveQuestionToRemove={(index) => setActiveQuestionToRemove(index)}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="flex justify-center">
                <div className="tooltip-premium tooltip text-neutral" data-tip={t("premiumFeature")}>
                  <button
                    onClick={() => onNewQuestionClick()}
                    className={`btn-outline btn-primary btn my-6 h-10 w-28 text-lg ${isTrial ? "btn-disabled" : ""}`}
                    disabled={isTrial}
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-8 mb-2 flex flex-col">
                <button
                  type="submit"
                  disabled={updating}
                  className={`inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-base-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                    updating ? "loading" : ""
                  }`}
                >
                  {t("save")}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  sm:w-auto"
                  onClick={close}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </TrialDegrate>
        </form>
      </Modal>
    </>
  );
};
