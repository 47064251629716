import { useState } from "react";

export const useFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const doc = document as any;
  const elem = document.documentElement as any;

  const openFullscreen = () => {
    if (elem.requestFullscreen) elem.requestFullscreen();
    else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen(); /* Safari */
    else if (elem.msRequestFullscreen) elem.msRequestFullscreen(); /* IE11 */
    setIsFullScreen(true);
  };

  /* Close fullscreen */
  const closeFullscreen = () => {
    if (doc.exitFullscreen) {
      try {
        doc.exitFullscreen();
      } catch (error) {
        // Check for TypeError: Not in fullscreen
        if (error instanceof TypeError && error.message === "Not in fullscreen") {
          // ignore
        } else {
          console.error("Error exiting fullscreen:", error);
        }
      }
    } else if (doc.webkitExitFullscreen) doc.webkitExitFullscreen(); /* Safari */
    else if (doc.msExitFullscreen) doc.msExitFullscreen(); /* IE11 */
    setIsFullScreen(false);
  };

  const toggleFullscreen = () => {
    isFullScreen ? closeFullscreen() : openFullscreen();
    setIsFullScreen(!isFullScreen);
  };

  return { toggleFullscreen, openFullscreen, closeFullscreen };
};
