import { ImagePicker } from "@components/ImagePicker";
import { Input } from "@components/Input";
import { Link } from "@components/Link";
import { useTranslation } from "@helpers/useTranslation";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useForm } from "react-hook-form";
import { FirebaseContext } from "src/helpers/firebase";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { useHasNoPin } from "src/helpers/useHasNoPin";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";

export const NewTeam = () => {
  const { t } = useTranslation("newteam");
  const navigate = useEventNavigate();
  const eventId = useEventId();
  const [event] = useEvent();
  const [teamAlreadyExists, setTeamAlreadyExists] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      teamName: "",
      email: "",
      password: "",
      contact: true,
    },
  });

  const teamName = watch("teamName");
  useEffect(() => {
    if (teamName) setTeamAlreadyExists(false);
  }, [teamName]);

  const isPromoEvent = event?.isPromo ?? false;

  const { pinAuth, storage, functions } = useContext(FirebaseContext);
  const [appData, setAppData] = usePersistedState();
  const [image, setImage] = useState<undefined | File>(undefined);
  const [exchangePinForToken, _, errorExchangePin] = useHttpsCallable(functions, "exchangePinForToken");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmit = async (data) => {
    setLoading(true);
    const pin = appData?.pin;
    const { email, contact, password } = data;
    try {
      const teamName = data.teamName;

      const displayName = teamName;
      const sanitisedName = teamName.replace(/[^a-zA-Z0-9]/g, "");

      const tokenResponse = await exchangePinForToken({
        pin,
        eventId,
        teamName: sanitisedName,
        displayName,
        email,
        contact,
        password,
        isNew: true,
      });
      const token = tokenResponse?.data as any;
      const valid = token !== undefined;
      if (!valid) return;

      const authResponse = await pinAuth(token);
      const uid = authResponse.user?.uid;
      setAppData({ ...appData, uid, isUploader: false });

      // Upload team avatar
      const imagePath = `events/${eventId}/teams/${uid}/avatar`;
      const imageRef = storageRef(storage, imagePath);
      await uploadBytes(imageRef, image);

      navigate("/challenges");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorExchangePin) {
      if (errorExchangePin.message.includes("Invalid PIN code")) {
        toast(t("invalidPinCode"), "🤷‍♂️", "error");
        navigate("/intro");
      } else if (errorExchangePin.message.includes("Team name already exists")) {
        setTeamAlreadyExists(true);
        toast(t("teamNameAlreadyExists"), "🤷‍♂️", "error");
      } else {
        toast(t("errorCreatingTeam"), "🤷‍♂️", "error");
      }
      console.error(errorExchangePin);
      setLoading(false);
    }
  }, [errorExchangePin]);

  useHasNoPin();

  // TODO: remove after rocking christmas event
  const isPlayers = eventId === "rockit";

  const title = isPlayers ? t("titlePlayers") : t("title");
  const about = isPlayers ? t("aboutPlayers") : t("about");
  const labelName = isPlayers ? t("labelPlayerName") : t("labelTeamName");
  const placeholderName = isPlayers ? t("placeholderPlayerName") : t("placeholderTeamName");
  const password = isPlayers ? t("playerPassword") : t("teamPassword");
  const teamPhoto = isPlayers ? t("playerPhoto") : t("teamPhoto");
  const teamNameAlreadyExists = isPlayers ? t("playerNameAlreadyExists") : t("teamNameAlreadyExists");
  const passwordTooltip = isPlayers ? t("playerPasswordTooltip") : t("teamPasswordTooltip");

  return (
    <div className="flex h-screen flex-col overflow-hidden">
      <main className="flex-1 overflow-y-scroll p-4">
        <div className={"px-4"}>
          <h1 className="mt-16 mb-8 text-center  text-4xl font-semibold">{title}</h1>
          <h1 className="mt-4 mb-8 text-center text-lg">{about}</h1>
          <form onSubmit={handleSubmit(onSubmit)} className="h-100">
            <div className="flex w-full flex-col px-4">
              <div className="align-center mx-auto flex w-full max-w-sm">
                <Input
                  label={labelName}
                  name="teamName"
                  title={t("invalidTeamName")}
                  required
                  placeholder={placeholderName}
                  requiredMessage={t("fieldRequired")}
                  errors={errors}
                  register={register}
                  className="max-w-md"
                />
              </div>
              {isPromoEvent && (
                <>
                  <span className="mt-2" />
                  <div className="align-center mx-auto flex w-full max-w-sm">
                    <Input
                      label={t("email")}
                      name="email"
                      required
                      placeholder={t("emailExample")}
                      type="email"
                      requiredMessage={t("fieldRequired")}
                      errors={errors}
                      register={register}
                      autoComplete="on"
                    />
                  </div>
                </>
              )}

              {teamAlreadyExists && (
                <>
                  <div className="mt-2 mb-4 text-center text-xs text-neutral">
                    <div className="text-error">{teamNameAlreadyExists}</div>
                    <div className="mt-1 text-xs">
                      {`${t("joinExistingTeam")} ${teamName} `}
                      <Link className="underline" to="selectteam">
                        {t("clickHere")}.
                      </Link>
                    </div>
                  </div>
                </>
              )}

              <span className="mt-2" />
              <div className="align-center mx-auto flex w-full max-w-sm">
                <Input
                  label={password}
                  name="password"
                  type="password"
                  placeholder={t("passwordPlaceholder")}
                  requiredMessage={t("fieldRequired")}
                  errors={errors}
                  register={register}
                  autoComplete="off"
                  tooltip={passwordTooltip}
                />
              </div>

              <ImagePicker allowUpload label={teamPhoto} uploadLabel={t("uploadPhoto")} onNewImage={setImage} />
              <div className="flex justify-center">
                <div className="mx-12 mb-12 mt-6 flex flex-col">
                  <button
                    disabled={loading || !image}
                    className={`${loading ? "loading" : ""} btn-primary btn mb-4 max-w-lg`}
                  >
                    {t("create")}
                  </button>
                  <div>
                    {isPromoEvent && (
                      <div className="text-muted max-w-xs px-4 text-center text-2xs">
                        <span>{t("confirmThatYouAgreeSharingEmail")}</span>{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};
