import { Input } from "@components/Input";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { useTranslation } from "@helpers/useTranslation";
import { Link, useNavigate } from "react-router-dom";
import { FirebaseContext } from "src/helpers/firebase";
import { useBranding } from "src/helpers/useBranding";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";

export const Login = () => {
  const { t } = useTranslation("admin");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toast = useToast();
  const { auth } = useContext(FirebaseContext);
  const [user] = useAuthState(auth);
  const [appData, setAppData] = usePersistedState();
  const [eventId, setEventId] = useState<string | undefined>();
  const [uid, setUid] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async ({ email, password }) => {
    setLoading(true);
    setSubmitted(false);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const uid = user.uid;
      const { claims } = await user.getIdTokenResult();
      const isAdmin = claims.role === "admin";
      if (!isAdmin) return;

      setUid(uid);
      const eventId = claims.events?.length === 1 ? claims.events[0] : undefined;
      setEventId(eventId);
    } catch (error) {
      const wrongPassword = error.code === "auth/wrong-password";
      const message = wrongPassword ? t("wrongPassword") : t("somethingWentWrong");
      toast(message, "🤷‍♂️", "error");

      // Log error to Sentry but only if it's not a wrong password error (nothing of interest)
      if (!wrongPassword) console.error(error);
    } finally {
      setSubmitted(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    if (uid && eventId && submitted) {
      setAppData({ ...appData, uid, isAdmin: true }, eventId);
      navigate(`/${eventId}/admin`, { replace: true });
      return;
    }

    // Logged in, but have more than one event, so redirect to myevents page
    if (uid) navigate("/events", { replace: true });
  }, [eventId, uid]);

  const { homepage, logoUrl, brand } = useBranding();

  return (
    <div className="flex flex-col">
      <div className="navbar top-0 z-50 flex justify-between border-b-2 px-2 shadow shadow-lg shadow-base-300">
        <div className="ml-4">
          <a href={homepage}>
            <img className={`color-white ${brand === "tuokis" ? "h-16" : "h-5"}`} src={logoUrl} />
          </a>
        </div>

        <div className="align-end muted mr-2 flex flex-col text-xs">
          <div>{t("noAccountYet")}</div>
          <Link to="/register" className="link self-end">
            {t("register")}
          </Link>
        </div>
      </div>
      <main className="mt-4 flex-1 flex-col p-4">
        <div>
          <h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">{t("login")}</h2>
          <p className="mt-8 mb-12 text-center text-sm">{t("loginCopy")}</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="h-100">
          <div className="flex justify-center px-8">
            <div className=" align-center flex w-full max-w-sm flex-col">
              <Input
                label={t("email")}
                name="email"
                required
                placeholder={t("email")}
                type="email"
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                autoComplete="on"
                className="max-w-lg"
              />
              <span className="mt-2" />
              <Input
                label={t("password")}
                name="password"
                required
                type="password"
                placeholder={t("password")}
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                autoComplete="on"
                className="max-w-lg"
              />
              <div className="mt-8 flex justify-center">
                <button disabled={loading} type="submit" className={`btn-primary btn px-8 ${loading ? "loading" : ""}`}>
                  {t("loginButton")}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="mt-4 flex justify-center text-xs leading-6 text-gray-500">
          <Link to="/forgotpassword">
            <div className="underline">{t("forgotPassword")}</div>
          </Link>
        </div>
      </main>
    </div>
  );
};
