import { FirebaseCachedVideo } from "@components/FirebaseCachedImage/FirebaseCachedVideo";
import { Share } from "@components/Share";
import { doc, getDoc, updateDoc, Timestamp } from "@firebase/firestore";
import { extractFrameFromVideoBlob } from "@helpers/extractFrameFromVideoBlob";
import { useTranslation } from "@helpers/useTranslation";
import { useProgressStore } from "@pages/challenges";
import { getBlob, ref, ref as storageRef, uploadBytes } from "firebase/storage";
import { FC, useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import ReactVideoRecorder from "@components/VideoRecorder/video-recorder";
import { FirebaseContext } from "src/helpers/firebase";
import { useConfetti } from "src/helpers/useConfetti";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { useTeam } from "src/helpers/useTeam";
import { useToast } from "src/helpers/useToast";
import { useEvent } from "@helpers/useEvent";

type Props = {
  challenge: VideoChallenge;
  completed: boolean;
  teamId: string;
  eventId: string;
};

const MAX_FILE_SIZE = 5 * 10240; // 50MB

export const Video: FC<Props> = (props) => {
  const { title, copy, id } = props.challenge;
  const { completed, eventId, teamId } = props;
  const { t } = useTranslation("challenges");
  const { functions, firestore, storage } = useContext(FirebaseContext);
  const [video, setVideo] = useState(undefined);
  const [event] = useEvent();
  const [frame, setFrame] = useState(null);
  const navigate = useEventNavigate();
  const [team] = useTeam();
  const [submitting, setSubmitting] = useState(false);
  useConfetti("submit-button");
  const toast = useToast();
  const [updateTeamPoints, _, error] = useHttpsCallable(functions, "updateTeamPoints");

  const getFrame = async (videoBlob: Blob): Promise<Blob> => {
    let extractionFailed = false;
    let blob: Blob;
    try {
      blob = await extractFrameFromVideoBlob(videoBlob, 1);
    } catch (error) {
      extractionFailed = true;
    }

    // Also consider it failed if resulting image is too small (likely pitch black image which can happen in the wild)
    extractionFailed = extractionFailed || (blob as File).size < 1024;

    if (extractionFailed) {
      // get event background image
      const backgroundUri = `events/${eventId}/background_light`;
      const backgroundRef = ref(storage, backgroundUri);
      try {
        blob = await getBlob(backgroundRef);
      } catch (error) {
        // No custom background image, will use default
        const defaultUrl = event?.type === "wedding" ? "/bg-wedding.webp" : "/bg-conference.webp";
        const response = await fetch(defaultUrl);
        if (response.ok) {
          blob = await response.blob();
        }
      }
    }
    return blob;
  };

  const handleInput = async (e) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files && files.length > 0) {
      const file = ((window as any).file = files[0]);
      if (file.size / 1024 > MAX_FILE_SIZE) {
        toast(t("videoFileTooLarge"), "🤷‍♂️", "error");
        console.error(`Video file too large. Compressed Video. Event: ${eventId}`);
        return;
      }

      try {
        const videoBlob = new Blob([file], { type: file.type });
        setVideo(videoBlob);
        const frame = await getFrame(videoBlob);
        setFrame(frame);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (error) {
      toast(t("problemOccured"), "🤷‍♂️", "error");
      console.error("Problem occured in updateTeamPoints function: ", error);
    }
  }, [error]);

  // @ts-ignore
  const setChallengeLoadingState = useProgressStore((state) => state.setChallenge);

  const updatePromise = async () => {
    try {
      setChallengeLoadingState(id, true);

      // Upload video and snapshot frame
      const framePath = `events/${eventId}/teams/${teamId}/solutions/${id}`;
      const frameRef = storageRef(storage, framePath);
      await uploadBytes(frameRef, frame);

      const videoPath = `events/${eventId}/teams/${teamId}/solutions/${id}.mp4`;
      const videoRef = storageRef(storage, videoPath);
      await uploadBytes(videoRef, video);

      // Update points
      await updateTeamPoints({ teamId, challengeId: id, eventId });
      const teamDocRef = doc(firestore, `events/${eventId}/teams/${teamId}`);

      try {
        const teamDoc = await getDoc(teamDocRef);
        const teamData = teamDoc.data();
        const solutionsMap = teamData?.solutionsMap || {};
        solutionsMap[id] = Timestamp.now();
        solutionsMap[`${id}-video`] = Timestamp.now();
        await updateDoc(teamDocRef, { solutionsMap });
      } catch (error) {
        console.error("Error updating solutions map: ", error);
        if (error.message.includes("PERMISSION_DENIED")) toast(t("sessionExpired"), "🤷‍♂️", "error");
        else toast(t("somethingWentWrong"), "🤷‍♂️", "error");
        toast(t("problemOccured"), "🤷‍♂️", "error");
      }
    } catch (error) {
      toast(t("problemOccured"), "🤷‍♂️", "error");
      console.error(error);
    } finally {
      // Give time to photo proccessing lambda to finish
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setChallengeLoadingState(id, false);
    }
  };

  const submit = async () => {
    if (!team) {
      toast(t("problemOccured"), "🤷‍♂️", "error");
      console.error(`Team is undefined. Team: ${team}, event: ${eventId}`);
      return;
    }

    setSubmitting(true);
    updatePromise(); // Don't wait for this to finish for better UX
    navigate("/challenges");
  };

  const mimeType = MediaRecorder.isTypeSupported("video/webm;codecs=vp9") ? "video/webm;codecs=vp9" : "video/mp4";

  return (
    <>
      <h1 className="mx-4 mt-12 mb-4 text-center  text-4xl font-semibold">{title}</h1>
      <div className="mt-4 mb-4 flex flex-col items-center">
        <div className="mx-6">
          {completed ? (
            <>
              <div className="flex max-w-lg flex-col justify-center">
                <h2 className="text-center text-xl font-bold">{t("challengeDone")}</h2>
                <div className="mt-4 mb-4 text-center text-lg">{t("challengeDoneSummary")}</div>
              </div>
              <div className="px-4 py-6">
                <Share title={title} text={title} uri={`events/${eventId}/teams/${teamId}/solutions/${id}`} />
                <div className="mx-auto max-w-lg p-2">
                  <FirebaseCachedVideo uri={`events/${eventId}/teams/${teamId}/solutions/${id}`} />
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="text-md mb-4 text-center">{copy}</p>
              <div className="flex w-full justify-center">
                <div className="h-[50vh] w-72">
                  <ReactVideoRecorder
                    mimeType={mimeType}
                    showReplayControls={true}
                    renderErrorView={() => (
                      <div className="flex w-full flex-col justify-center">
                        <div className="mx-8 flex flex-col justify-center">
                          <div className="mb-6 text-lg">{t("uploadVideoFromDevice")}</div>
                          <div className="mb-12 text-xs text-gray-300">{t("uploadVideoNote")}</div>
                          <input
                            onChange={handleInput}
                            type="file"
                            accept="video/*"
                            className="rounded-lg border border-primary bg-white p-3 text-sm text-primary"
                          />
                          {video && (
                            <div className="mt-6 flex justify-center">
                              <button
                                disabled={!video || submitting}
                                onClick={submit}
                                className={`btn-primary btn ${submitting ? "loading" : ""}`}
                                id="submit-button"
                              >
                                {t("submit")}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    timeLimit={60000}
                    isFlipped={false}
                    constraints={{
                      audio: true,
                    }}
                    renderDisconnectedView={() => <></>}
                    onRecordingComplete={async (videoBlob: Blob) => {
                      setVideo(videoBlob);
                      const frame = await getFrame(videoBlob);
                      setFrame(frame);
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {completed ? null : (
            <div className="mt-8 mb-6 flex justify-center">
              <button
                disabled={!video || submitting}
                onClick={submit}
                className={`btn-primary btn ${submitting ? "loading" : ""}`}
                id="submit-button"
              >
                {t("submit")}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
