import { EventPreviewModal } from "@components/EventPreviewModal";
import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { BackIcon } from "@components/Icons/Back";
import { BarsIcon } from "@components/Icons/Bars";
import { CalendarIcon } from "@components/Icons/CalendarIcon";
import { ChallengesIcon } from "@components/Icons/Challenges";
import { ChatIcon } from "@components/Icons/Chat";
import { ClickPowIcon } from "@components/Icons/ClickPow";
import { EyeIcon } from "@components/Icons/EyeIcon";
import { HomeIcon } from "@components/Icons/Home";
import { LanguageIcon } from "@components/Icons/Language";
import { LogoutIcon } from "@components/Icons/Logout";
import { SettingsIcon } from "@components/Icons/Settings";
import { PremiumModal } from "@components/PremiumModal/PremiumModal";
import { useTranslation } from "@helpers/useTranslation";
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "src/helpers/firebase";
import { getLocaleFlag, useLocales } from "src/helpers/locales";
import { useBranding } from "src/helpers/useBranding";
import { useCurrentPage } from "src/helpers/useCurrentPage";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { AppData, usePersistedState } from "src/helpers/usePersistedState";
import { useTeam, useTeamId } from "src/helpers/useTeam";

type Props = {
  isAdmin?: boolean;
  isUploader?: boolean;
  backLink?: string;
  showBack?: boolean;
  useAllLocales?: boolean;
  showPremiumCopy?: boolean;
  hideUpgrade?: boolean;
};

export const TopNav: FC<Props> = ({
  showBack,
  isAdmin,
  isUploader,
  backLink,
  useAllLocales,
  showPremiumCopy,
  hideUpgrade,
}) => {
  const { auth } = useContext(FirebaseContext);
  const navigate = useNavigate();
  const eventNavigate = useEventNavigate();
  const [team] = useTeam();
  const { t, i18n } = useTranslation("admin");
  const [appData, setAppData] = usePersistedState();
  const goBack = () => (backLink ? eventNavigate(backLink) : navigate(-1));

  const goToSettings = () => eventNavigate("/teamsettings");
  const goToAdminHome = () => eventNavigate("/admin");
  const goToChallenges = () => eventNavigate("/challenges");
  const goToMyEvents = () => navigate("/events");
  const eventId = useEventId();
  const [event] = useEvent();
  const teamId = useTeamId();
  const isPremium = event?.isPremium ?? false;
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [currentPage, root] = useCurrentPage();
  const locales = useLocales(useAllLocales);
  const [eventPreviewModalVisible, setEventPreviewModalVisible] = useState(false);

  const { instaUrl } = useBranding();
  const callOutToInstagram = () => {
    if (window) window.open(instaUrl, "_blank");
  };

  const logout = async () => {
    setAppData({ uid: undefined, isUploader: undefined, isAdmin: undefined, pin: appData.pin } as AppData);
    eventNavigate("/");
    setTimeout(() => eventNavigate("/"), 100);
    auth.signOut();
  };

  const { logoUrl, brand } = useBranding();

  // Need to render differently because logos for Tuokis vs Camdeed are different aspect rations
  const premiumFreeCopyClassname = brand === "tuokis" ? "-mt-14 ml-2" : "-mt-12";

  // When outside of event (e.g. when selecting which event to edit) we hide a few extra topnav items that only makes sense with specific event in mind
  const hideAdminEventNav = root === "events" || root === "addevent" || root === "branding";

  if (isAdmin)
    return (
      <>
        {showPremiumModal && (
          <PremiumModal
            show={showPremiumModal}
            onClose={() => {
              setShowPremiumModal(false);
              setAppData({ ...appData, hideUpgradePopup: false });
            }}
          />
        )}
        {eventPreviewModalVisible && (
          <EventPreviewModal show={eventPreviewModalVisible} onClose={() => setEventPreviewModalVisible(false)} />
        )}
        <div className="navbar absolute sticky top-0 z-10 border-b-2 px-2 shadow-lg shadow-base-300">
          <div className="flex w-full justify-between px-2">
            {(showBack || backLink) && (
              <a onClick={goBack} className="link">
                <div className="flex flex-row font-light">
                  <BackIcon className="mr-1 h-6 w-6" />
                  <span>{t("back")}</span>
                </div>
              </a>
            )}
            <div>
              {isPremium ? (
                <>
                  <div className={`flex items-center tracking-wider ${!showPremiumCopy ? "pl-8" : ""}`}>
                    <img className={`color-white ${brand === "tuokis" ? "h-16" : "h-5"} `} src={logoUrl} />
                  </div>
                  {showPremiumCopy ? (
                    <div className={`${premiumFreeCopyClassname} flex h-16 items-end`}>
                      <h2 className="ml-1 text-3xs tracking-wider">{t("premium")}</h2>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <button
                    onClick={() => setShowPremiumModal(true)}
                    className="flextracking-wider flex w-full items-center"
                  >
                    <img className={`color-white ml-8 ${brand === "tuokis" ? "h-16" : "h-5"}`} src={logoUrl} />{" "}
                  </button>
                  <button
                    onClick={() => setShowPremiumModal(true)}
                    className="flextracking-wider flex w-full items-center"
                  >
                    {hideUpgrade ? null : (
                      <div className={`${premiumFreeCopyClassname} flex h-16 items-end`}>
                        <h2 className="text-free-text ml-8 animate-wiggle text-3xs">{t("appFree")}</h2>
                      </div>
                    )}
                  </button>
                </>
              )}
            </div>
            <div className="flex items-center">
              <div className="dropdown-end dropdown">
                <label tabIndex={0} className="btn-primary btn-ghost btn-circle btn">
                  <LanguageIcon className="hidden" />
                  <div className="text-lg">{getLocaleFlag(i18n.language as AppLocale)}</div>
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu rounded-box menu-compact mt-3 border border-base-300 bg-base-200 p-2 shadow"
                >
                  {locales.length > 1 && (
                    <li className="flex justify-center align-bottom">
                      {locales.map((locale) => (
                        <button
                          key={locale}
                          className={`my-1 flex items-center rounded-lg border-2 border-base-300 bg-base-200 ${
                            locale === i18n.language ? "border-primary font-semibold underline" : ""
                          }}`}
                          onClick={() => {
                            i18n.changeLanguage(locale);
                            setAppData({ ...appData, locale });
                            (document?.activeElement as any)?.blur();
                          }}
                        >
                          <span className="text-xs uppercase text-neutral">{locale}</span>
                          <span className="text-lg">{getLocaleFlag(locale)}</span>
                        </button>
                      ))}
                    </li>
                  )}
                </ul>
              </div>
              <div className="dropdown-end dropdown">
                <label tabIndex={0} className="btn-primary btn-ghost btn-circle btn">
                  <BarsIcon />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu rounded-box menu-compact mt-3 w-52 border border-base-300 bg-base-200 p-2 shadow"
                >
                  {!hideAdminEventNav && (
                    <li>
                      <button
                        onClick={goToAdminHome}
                        disabled={currentPage === "admin"}
                        className={`flex flex-row ${
                          currentPage === "admin" ? "pointer-events-none text-slate-600 text-opacity-30 underline" : ""
                        }`}
                      >
                        <HomeIcon />
                        <span>{t("adminPanel")}</span>
                      </button>
                    </li>
                  )}
                  <li>
                    <button className={`flex flex-row`} onClick={() => setEventPreviewModalVisible(true)}>
                      <EyeIcon />
                      <span>{t("eventPreview")}</span>
                    </button>
                  </li>
                  <li>
                    <button
                      disabled={currentPage === "events"}
                      className={`flex flex-row ${
                        currentPage === "myevents" ? "pointer-events-none text-slate-600 text-opacity-30 underline" : ""
                      }`}
                      onClick={goToMyEvents}
                    >
                      <CalendarIcon />
                      <span>{t("myEvents")}</span>
                    </button>
                  </li>
                  <li>
                    <button onClick={callOutToInstagram} className="flex flex-row">
                      <ChatIcon />
                      <span>{t("supportChat")}</span>
                    </button>
                  </li>
                  {!isPremium && !hideAdminEventNav && (
                    <li>
                      <button onClick={() => setShowPremiumModal(true)} className="flex flex-row">
                        <ClickPowIcon />
                        <span className="font-semibold">{t("buyNow")}</span>
                      </button>
                    </li>
                  )}

                  <li></li>
                  <li>
                    <button onClick={logout} className="flex flex-row">
                      <LogoutIcon />
                      <span>{t("logout")}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <div className="navbar absolute sticky top-0 z-50 border-b-2 px-2 shadow-lg shadow-base-300">
      <div className="flex-1">
        {showBack || backLink ? (
          <a onClick={goBack} className="link ml-2">
            <div className="flex flex-row font-light">
              <BackIcon />
              {t("back")}
            </div>
          </a>
        ) : (
          <a className="ml-4 text-lg uppercase">{team?.displayName ?? team?.name ?? ""}</a>
        )}
      </div>
      <div className="flex items-center">
        <div className="dropdown-end dropdown mr-2">
          <label tabIndex={0} className="btn-primary btn-ghost btn-circle btn">
            <LanguageIcon className="hidden" />
            <div className="text-lg">{getLocaleFlag(i18n.language as AppLocale)}</div>
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu rounded-box menu-compact mt-3 border border-base-300 bg-base-200 p-2 shadow"
          >
            {locales.length > 1 && (
              <li className="flex justify-center align-bottom">
                {locales.map((locale) => (
                  <button
                    key={locale}
                    className={`my-1 flex items-center rounded-lg border border-base-300 bg-base-200 ${
                      locale === i18n.language ? "border-primary font-semibold underline" : ""
                    }}`}
                    onClick={() => {
                      i18n.changeLanguage(locale);
                      setAppData({ ...appData, locale });
                      (document?.activeElement as any)?.blur();
                    }}
                  >
                    <span className="text-xs uppercase text-neutral">{locale}</span>
                    <span className="text-lg">{getLocaleFlag(locale)}</span>
                  </button>
                ))}
              </li>
            )}
          </ul>
        </div>
        <div className="dropdown-end dropdown">
          <label tabIndex={0} className="btn-ghost btn-circle avatar btn">
            {team ? (
              <div className="h-11 w-11 rounded-full border-2 border-base-200">
                {team.isUploader ? (
                  <FirebaseCachedImage uri="anonymous.webp" alt="" />
                ) : (
                  <FirebaseCachedImage retries={4} uri={`events/${eventId}/teams/${teamId}/avatar_thumb`} alt="" />
                )}
              </div>
            ) : null}
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu rounded-box menu-compact mt-3 w-40 border border-base-300 bg-base-200 p-2 shadow"
          >
            {!isUploader && (
              <li>
                <button
                  disabled={currentPage === "challenges"}
                  className={`flex flex-row ${
                    currentPage === "challenges" ? "pointer-events-none text-slate-600 text-opacity-30 underline" : ""
                  }`}
                  onClick={goToChallenges}
                >
                  <ChallengesIcon />
                  <span>{t("challenges")}</span>
                </button>
              </li>
            )}
            {!isUploader && (
              <li>
                <button
                  disabled={currentPage === "teamsettings"}
                  className={`flex flex-row ${
                    currentPage === "teamsettings" ? "pointer-events-none text-slate-600 text-opacity-30 underline" : ""
                  }`}
                  onClick={goToSettings}
                >
                  <SettingsIcon />
                  <span>{t("settings")}</span>
                </button>
              </li>
            )}
            <li>
              <button onClick={callOutToInstagram} className="flex flex-row">
                <ChatIcon />
                <span>{t("supportChat")}</span>
              </button>
            </li>
            <li></li>
            <li>
              <button onClick={logout} className="flex flex-row">
                <LogoutIcon />
                <span>{t("logout")}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
