import i18n from "i18next";
import { useEffect, useRef } from "react";
import "react-html5-camera-photo/build/css/index.css";
import { initReactI18next } from "react-i18next";
import { FirebaseContext, initFirebase } from "src/helpers/firebase";
import { ImageCacheContext } from "src/helpers/imageCache";

import "./styles/globals.css";
import "./styles/imagePreview.css";

import enAdmin from "./locales/en/admin.json";
import enChallenges from "./locales/en/challenges.json";
import enHighscores from "./locales/en/highscores.json";
import enHome from "./locales/en/home.json";
import enIntro from "./locales/en/intro.json";
import enNewTeam from "./locales/en/newteam.json";
import enRegister from "./locales/en/register.json";
import enSelect from "./locales/en/select.json";
import enSelectTeam from "./locales/en/selectteam.json";
import enTeams from "./locales/en/teams.json";
import enTeamSettings from "./locales/en/teamsettings.json";
import enUpload from "./locales/en/upload.json";
import enContact from "./locales/en/contact.json";

import ltAdmin from "./locales/lt/admin.json";
import ltChallenges from "./locales/lt/challenges.json";
import ltHighscores from "./locales/lt/highscores.json";
import ltHome from "./locales/lt/home.json";
import ltIntro from "./locales/lt/intro.json";
import ltNewTeam from "./locales/lt/newteam.json";
import ltRegister from "./locales/lt/register.json";
import ltSelect from "./locales/lt/select.json";
import ltSelectTeam from "./locales/lt/selectteam.json";
import ltTeams from "./locales/lt/teams.json";
import ltTeamSettings from "./locales/lt/teamsettings.json";
import ltUpload from "./locales/lt/upload.json";
import ltContact from "./locales/lt/contact.json";

import { Toaster } from "react-hot-toast";
import { useIsCamdeed } from "@helpers/useIsCamdeed";
import { useTranslation } from "@helpers/useTranslation";
import { AppRoutes } from "./AppRoutes";
import { BrowserRouter } from "react-router-dom";

i18n
  .use(initReactI18next) // Bind react-i18next to i18next
  .init({
    resources: {
      en: {
        challenges: enChallenges,
        highscores: enHighscores,
        home: enHome,
        intro: enIntro,
        newteam: enNewTeam,
        selectteam: enSelectTeam,
        teams: enTeams,
        admin: enAdmin,
        register: enRegister,
        upload: enUpload,
        select: enSelect,
        teamsettings: enTeamSettings,
        contact: enContact,
      },
      lt: {
        challenges: ltChallenges,
        highscores: ltHighscores,
        home: ltHome,
        intro: ltIntro,
        newteam: ltNewTeam,
        selectteam: ltSelectTeam,
        teams: ltTeams,
        admin: ltAdmin,
        register: ltRegister,
        upload: ltUpload,
        select: ltSelect,
        teamsettings: ltTeamSettings,
        contact: ltContact,
      },
    },
    lng: "lt", // Set the default language
    fallbackLng: "lt", // Fallback if a translation for the current language is missing
    interpolation: { escapeValue: false }, // Disable HTML escaping
  });

const Application = () => {
  const cache = useRef(new Map<string, string>());
  const isCamdeed = useIsCamdeed();
  const { i18n } = useTranslation();
  useEffect(() => {
    if (isCamdeed) i18n.changeLanguage("en");
  }, [isCamdeed]);

  return (
    <>
      <Toaster />
      <ImageCacheContext.Provider value={cache}>
        <FirebaseContext.Provider value={initFirebase()}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </FirebaseContext.Provider>
      </ImageCacheContext.Provider>
    </>
  );
};

export default Application;
