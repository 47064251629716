import { DateInput } from "@components/DateInput";
import { Input } from "@components/Input";
import { useContext, useEffect, useRef, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useForm } from "react-hook-form";
import { useTranslation } from "@helpers/useTranslation";
import { Link } from "react-router-dom";
import { FirebaseContext } from "src/helpers/firebase";
import { getLocaleFlag } from "src/helpers/locales";
import { BASE_STORAGE_PATH, usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";
import * as Sentry from "@sentry/react";
import { PremiumModal } from "@components/PremiumModal";
import { signInWithCustomToken } from "firebase/auth";
import { useBranding } from "src/helpers/useBranding";
import useFormPersist from "react-hook-form-persist";
import { Toggle } from "@components/Toggle";

const eventTypes = ["wedding", "birthday", "other"];

export const Register = () => {
  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const handleShowPicker = () => dateInputRef?.current?.showPicker && dateInputRef.current.showPicker();

  const { t, i18n } = useTranslation("register");
  const {
    register,
    watch,
    getValues,
    setValue,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useFormPersist(`${BASE_STORAGE_PATH}-register`, { watch, setValue });

  const toast = useToast();
  const { auth, functions } = useContext(FirebaseContext);
  const [appData, setAppData] = usePersistedState();
  const [eventId, setEventId] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [uid, setUid] = useState<string | undefined>();

  const [createNewEvent, _loading, error] = useHttpsCallable(functions, "createNewEvent");
  const [loading, setLoading] = useState(false);
  const { brand } = useBranding();

  const onSubmit = async (data) => {
    const passwordsMatch = data.password === data.confirmPassword;
    if (!passwordsMatch) {
      toast(t("passwordsDoNotMatch"), "🤷‍♂️", "error");
      setError("confirmPassword", { message: t("passwordsDoNotMatch") });
      return;
    }

    const usedReservedWordForEventId = [
      "admin",
      "report",
      "events",
      "addevent",
      "branding",
      "gallery",
      "login",
      "register",
      "forgotpassword",
      "undefined",
      "contact",
    ].includes(data.eventId);
    if (usedReservedWordForEventId) {
      toast(t("usedReservedWord"), "🤷‍♂️", "error");
      setError("eventId", { message: t("usedReservedWord") });
      return;
    }
    const { date, email, password, prepopulate, pin, name, eventName, type, sendNews } = data;
    const eventId = data.eventId.toLowerCase();
    setEventId(eventId);
    setEmail(email);
    const prepopulateLang = i18n.language === "lt" ? "lt" : "en";

    setLoading(true);
    const response = (await createNewEvent({
      brand,
      email,
      password,
      prepopulate,
      prepopulateLang,
      type,
      eventId,
      pin,
      name,
      eventName,
      sendNews,
      date,
    })) as any;
    const { token, uid: userUid } = response?.data ?? {};

    if (userUid) {
      await signInWithCustomToken(auth, token);
      reset();

      setUid(userUid);
      try {
        Sentry.captureMessage(`New event created: ${eventId}`);
      } catch {
        console.error("Sentry failed to capture message");
      }
    }
  };

  useEffect(() => {
    if (eventId && uid) {
      setAppData({ ...appData, uid, isAdmin: true }, eventId);
      setShowPremiumModal(true);
    }
  }, [eventId, uid]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      console.error("Event creation failed!", error);
      if (error.message.includes("already exists")) {
        toast(`${t("handleExists1")} '${eventId}' ${t("handleExists2")}`, "🤷‍♂️", "error");
        return;
      }
      if (error.message.includes("email address is already in use by another account")) {
        toast(`${t("emailExists1")} '${email}' ${t("emailExists2")}`, "🤷‍♂️", "error");
        return;
      }

      toast(t("creationFailed"), "🤷‍♂️", "error");
    }
  }, [error]);

  const locales: AppLocale[] = ["en", "lt"];

  const { homepage, logoUrl } = useBranding();

  // ensure event handle is lowercase and remove special characters
  const eventIdField = watch("eventId");
  useEffect(() => {
    if (eventIdField) {
      let value = eventIdField.toLowerCase();
      value = value.replace(/[^a-z0-9]/g, "");
      setValue("eventId", value);
    }
  }, [eventIdField]);

  // ensure pin is only numbers
  const pinField = watch("pin");
  useEffect(() => {
    if (pinField) {
      const value = pinField.replace(/[^0-9]/g, "");
      setValue("pin", value);
    }
  }, [pinField]);

  const [showPremiumModal, setShowPremiumModal] = useState(false);

  return (
    <>
      <PremiumModal show={showPremiumModal} redirectBackUri={`/${eventId}/admin`} fallbackEventId={eventId} />
      <div className="flex flex-col">
        <div className="navbar top-0 z-50 flex justify-between px-2 shadow-lg shadow-base-300">
          <div className="ml-4">
            <a href={homepage}>
              <img className={`color-white ${brand === "tuokis" ? "h-16" : "h-5"}`} src={logoUrl} />{" "}
            </a>
          </div>
          <div>
            <div>
              <div className="mr-6 flex w-full justify-center">
                <div className="dropdown-end dropdown m-0 p-0">
                  <label tabIndex={0} className="btn-primary btn-ghost btn-circle btn">
                    <div className="text-lg">{getLocaleFlag(i18n.language as AppLocale)}</div>
                  </label>
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu rounded-box menu-compact mt-3 border border-base-300 bg-base-200 p-2 shadow"
                  >
                    {locales.length > 1 && (
                      <li className="flex justify-center align-bottom">
                        {locales.map((locale) => (
                          <button
                            key={locale}
                            className={`my-1 flex items-center rounded-lg border border-base-300 bg-base-200 ${
                              locale === i18n.language ? "border-primary font-semibold underline" : ""
                            }}`}
                            onClick={() => {
                              i18n.changeLanguage(locale);
                              (document?.activeElement as any)?.blur();
                            }}
                          >
                            <span className="text-xs uppercase text-neutral">{locale}</span>
                            <span className="text-lg">{getLocaleFlag(locale)}</span>
                          </button>
                        ))}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="align-end muted mr-2 flex flex-col text-xs">
              <div>{t("alreadyHaveAccount")}</div>
              <Link to="/login" className="link self-end">
                {t("signIn")}
              </Link>
            </div>
          </div>
        </div>
        <main className="mt-12 flex flex-col items-center px-6">
          <div className="flex flex-col justify-center">
            <h1 className="text-center text-4xl font-semibold">{t("title")}</h1>

            <p className="text-md mt-4 mb-6 max-w-2xl self-center text-center">{t("about")}</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="h-100 flex flex-col justify-center px-4">
            <h2 className="mb-4 w-full px-4 pt-8 text-center text-2xl ">{t("loginDetails")}</h2>
            <div className="flex flex-col justify-center">
              <Input
                label={t("name")}
                name="name"
                required
                placeholder={t("nameExample")}
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                autoComplete="on"
              />
              <Input
                label={t("email")}
                name="email"
                required
                placeholder={t("emailExample")}
                type="email"
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                autoComplete="on"
              />
              <span className="mt-2" />
              <Input
                label={t("password")}
                name="password"
                required
                type="password"
                placeholder={t("password")}
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                autoComplete="on"
              />
              <span className="mt-2" />
              <Input
                label={t("confirmPassword")}
                name="confirmPassword"
                required
                type="password"
                placeholder={t("confirmPassword")}
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                autoComplete="on"
              />
              <h2 className="mb-4 w-full px-4 pt-12 text-center text-2xl">{t("eventDetails")}</h2>
              <div className="align-start mb-2 flex flex-row" onClick={() => handleShowPicker()}>
                <DateInput
                  ref={dateInputRef}
                  label={t("dateField")}
                  name="date"
                  register={register}
                  errors={errors}
                  requiredMessage={t("fieldRequired")}
                  required
                />
              </div>
              <div className="align-start flex flex-row">
                <Input
                  label={t("eventHandle")}
                  name="eventId"
                  required
                  placeholder={t("eventHandleExample")}
                  title={t("handleValidationError")}
                  pattern="^[a-zA-Z0-9]+$"
                  requiredMessage={t("fieldRequired")}
                  errors={errors}
                  register={register}
                  tooltip={t("handleTooltip")}
                />
              </div>
              <span className="mt-2" />
              <Input
                label={t("eventName")}
                name="eventName"
                required
                placeholder={t("eventNameExample")}
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
              />
              <span className="mt-2" />
              <div className="align-start flex flex-row">
                <Input
                  label={t("pinCode")}
                  name="pin"
                  placeholder={t("pinCodeExample")}
                  pattern="^\d{4}$"
                  title={t("pinCodeValidationError")}
                  maxLength={4}
                  required
                  requiredMessage={t("fieldRequired")}
                  errors={errors}
                  register={register}
                  tooltip={t("pinCodeTooltip")}
                />
              </div>
              <div className="mb-4 mt-4 flex w-full flex-col">
                <label className="label">
                  <span className="label-text">
                    {t("eventType")}
                    <span className="text-warning">{` *`}</span>
                  </span>
                </label>
                <select className="select-bordered select" required {...register("type", { required: true })}>
                  {eventTypes.map((type) => (
                    <option key={type} value={type}>
                      {t(type)}
                    </option>
                  ))}
                  {errors["type"] && <span className="pl-1 pt-1 text-xs text-warning">{t("requiredMessage")}</span>}
                </select>
              </div>
              <Toggle
                label={t("prepopulate")}
                name="prepopulate"
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
              <div className="form-control mt-12 mb-6">
                <label className="label cursor-pointer">
                  <span className="label-text text-sm">{t("subscribe")}</span>
                  <input type="checkbox" className="checkbox-primary checkbox" {...register("sendNews")} />
                </label>
              </div>

              <button
                type="submit"
                disabled={loading}
                className={`btn-primary btn mb-6 max-w-sm px-4 ${loading ? "loading" : ""}`}
              >
                {t("register")}
              </button>
            </div>
          </form>
          <div className="text-muted max-w-xs px-4 text-center text-2xs">
            <span>{t("confirmThatYouRead")}</span>{" "}
            <a className="underline" href={`${homepage}/terms`}>
              {t("terms")}
            </a>{" "}
            <span>{t("and")}</span>{" "}
            <a className="underline" href={`${homepage}/privacy`}>
              {t("privacyPolicy")}
            </a>
          </div>
          <div className="mb-24" />
        </main>
      </div>
    </>
  );
};
