import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { useTranslation } from "@helpers/useTranslation";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { decodePin } from "src/helpers/codec";
import { getBeginCopy, getLocaleFlag, useLocales } from "src/helpers/locales";
import { useBranding } from "src/helpers/useBranding";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { useFirebaseCachedImageUrl } from "src/helpers/useFirebaseCachedImageUrl";
import { usePersistedState } from "src/helpers/usePersistedState";

export const Landing = () => {
  const navigate = useEventNavigate();
  const { i18n } = useTranslation();
  const [appData, setAppData] = usePersistedState();

  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pinEncoded = params.get("p");
    if (pinEncoded) {
      const pin = decodePin(pinEncoded);
      setAppData({ ...appData, pin });
    }
  }, [location.search]);

  const [event, loading] = useEvent();
  const { name, type } = event || {};
  let icon = "";
  if (type === "wedding") icon = "💍";
  if (type === "birthday") icon = "🎂";

  const eventId = useEventId();
  const imageUrl = useFirebaseCachedImageUrl(`events/${eventId}/background_light`, `events/${eventId}/background.jpg`);
  const defaultUrl = type === "wedding" ? "/bg-wedding.webp" : "/bg-conference.webp";
  const backgroundImage = imageUrl ? `url(${imageUrl})` : `url(${defaultUrl})`;

  const locales = useLocales();

  const { brandName, homepage, logoUrl } = useBranding();

  // Non-existing event, redirect to landing page
  if (!event && !loading) window.location.replace(homepage);
  if (!event) return null;
  const hasCustomBranding = event?.hasBranding;
  const hideTitle = eventId === "cci";

  return (
    <div
      style={{ backgroundImage }}
      className={`flex h-screen justify-center overflow-hidden bg-gray-800 bg-cover bg-center bg-blend-overlay`}
    >
      <div className="container flex justify-center">
        <main className="fadein flex w-full max-w-md flex-1 flex-col items-center justify-between px-4 py-4">
          <div className="mt-16">
            <h1 className="text-center text-4xl font-bold uppercase leading-normal text-white sm:text-5xl">
              {!hideTitle && <div>{name}</div>}
              <span className="text-3xl">{icon}</span>
            </h1>
          </div>
          <div className="flex w-full max-w-xs flex-col justify-center align-bottom">
            {locales.map((locale) => (
              <button
                key={locale}
                className="btn-outline btn mb-7 border border-base-300 bg-base-200 bg-opacity-80 uppercase text-neutral"
                onClick={(e) => {
                  e.preventDefault();
                  setAppData({ ...appData, locale });
                  i18n.changeLanguage(locale);
                  navigate("/intro");
                }}
              >
                <span className="-ml-3 mr-4 text-xl">{getLocaleFlag(locale)}</span>
                {getBeginCopy(locale)}
              </button>
            ))}
          </div>
          <div className="bottom-0 max-w-lg text-center">
            <div>
              <>
                {hasCustomBranding ? (
                  <div className="h-36">
                    <FirebaseCachedImage uri={`events/${eventId}/branding`} alt={brandName} />
                  </div>
                ) : (
                  <div className="h-20 opacity-70">
                    <a href={homepage}>
                      <img src={logoUrl} alt={brandName} className="mx-auto w-32" />
                    </a>
                  </div>
                )}
              </>
              <Link to={`/login`}>
                <div className="mt-8 mb-2 p-2 text-center text-sm text-slate-200 underline">
                  <span>Admin Login</span>
                </div>
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
