import { Link } from "@components/Link";
import { TopNav } from "@components/TopNav";
import { useTranslation } from "@helpers/useTranslation";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { SlideshowIcon } from "@components/Icons/Slideshow";
import { TeamsIcon } from "@components/Icons/Teams";
import { GalleryIcon } from "@components/Icons/Gallery";
import { TweakIcon } from "@components/Icons/Tweak";
import { classNames } from "src/helpers/classnames";
import { useBranding } from "src/helpers/useBranding";
import { QRIcon } from "@components/Icons/QR";
import { useEffect, useMemo, useState } from "react";
import { QRModal } from "./QRModal";
import { SettingsIcon } from "@components/Icons/Settings";
import { EventDateModal } from "@components/EventDateModal";
import { EventPreviewModal } from "@components/EventPreviewModal";
import { EyeIcon } from "@components/Icons/EyeIcon";
import { usePersistedState } from "@helpers/usePersistedState";

const EventDate = ({ event, onAddEventDate }) => {
  const { t } = useTranslation("admin");

  const daysLeft = useMemo(() => {
    if (!event?.date) return 0;
    const eventDate = new Date(event.date);
    const today = new Date();
    const diff = eventDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(diff / (1000 * 3600 * 24));

    return daysLeft > 0 ? daysLeft : 0;
  }, [event]);

  if (!event) return null;
  return (
    <>
      {event?.date ? (
        <>
          <time className="font-medium" dateTime={event?.date}>
            {event?.date}
          </time>
          {daysLeft ? (
            <div className="text-light text-right text-3xs text-gray-500">{t("daysLeft", { daysLeft })}</div>
          ) : null}
        </>
      ) : (
        <a onClick={onAddEventDate} className="text-gray-800 underline">
          {t("addEventDate")}
        </a>
      )}
    </>
  );
};

type Action = {
  title: string;
  description: string;
  href?: string;
  onClick?: () => void;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconForeground: string;
  iconBackground: string;
};

export const Admin = () => {
  const { t } = useTranslation("admin");
  const eventId = useEventId();
  const [event] = useEvent();
  const { appDomain } = useBranding();
  const [dateModalVisible, setDateModalVisible] = useState(false);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [eventPreviewModalVisible, setEventPreviewModalVisible] = useState(false);

  const actions = useMemo<Action[]>(
    () => [
      {
        title: t("editEvent"),
        description: t("editEventDesc"),
        href: "customise",
        icon: SettingsIcon,
        iconForeground: "text-blue-700",
        iconBackground: "bg-blue-50",
      },
      {
        title: t("editChallenges"),
        description: t("editChallengesDesc"),
        href: "editchallenges",
        icon: TweakIcon,
        iconForeground: "text-teal-700",
        iconBackground: "bg-teal-50",
      },
      {
        title: t("manageTeams"),
        description: t("manageTeamsDesc"),
        href: "manageteams",
        icon: TeamsIcon,
        iconForeground: "text-purple-700",
        iconBackground: "bg-purple-50",
      },
      {
        title: t("qrCode"),
        description: t("explainQrCode"),
        onClick: () => setQrModalVisible(true),
        icon: QRIcon,
        iconForeground: "text-gray-900",
        iconBackground: "bg-gray-100",
      },
      {
        title: t("slideshow"),
        description: t("slideshowDesc"),
        href: "slideshow",
        icon: SlideshowIcon,
        iconForeground: "text-yellow-800",
        iconBackground: "bg-yellow-50",
      },
      {
        title: t("gallery"),
        description: t("galleryDesc"),
        href: "gallery",
        icon: GalleryIcon,
        iconForeground: "text-red-700",
        iconBackground: "bg-red-50",
      },
    ],
    [t],
  );

  // Restore scroll position the first time user navigates to this page
  // Scroll position is captured in the location state when user clicks on an admin action link
  const [appData, setAppData] = usePersistedState();
  useEffect(() => {
    const hasScrollPosition = !!appData?.persistedScrollPosition;
    if (!hasScrollPosition) return;

    window.scrollTo(0, appData.persistedScrollPosition);
    setAppData({ ...appData, persistedScrollPosition: undefined });
  }, [appData]);

  useEffect(() => {
    window.onbeforeunload = () => {
      setAppData({ ...appData, persistedScrollPosition: window?.scrollY });
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const isTrial = event?.isTrial || false;
  const isPremium = event?.isPremium || false;

  const persistScrollPosition = () => {
    setAppData({ ...appData, persistedScrollPosition: window?.scrollY });
  };

  return (
    <>
      <TopNav isAdmin showPremiumCopy />
      {qrModalVisible && <QRModal onCancel={() => setQrModalVisible(false)} show={qrModalVisible} />}
      {dateModalVisible && <EventDateModal show={dateModalVisible} onClose={() => setDateModalVisible(false)} />}
      {eventPreviewModalVisible && (
        <EventPreviewModal show={eventPreviewModalVisible} onClose={() => setEventPreviewModalVisible(false)} />
      )}
      <main className="flex-1 flex-col">
        <div className="flex flex-col items-center justify-center">
          <div className="flex max-w-3xl flex-col px-6 py-4">
            <h1 className="mt-12 mb-4 text-center text-3xl font-semibold">{t("pageTitle")}</h1>
            <h4 className="text-center text-lg font-semibold text-neutral">
              {eventId}
              {isTrial && <span className="mt-2 text-center text-sm font-light uppercase">{` (${t("trial")})`}</span>}
            </h4>
            <div className="mt-12 flex flex-col justify-between rounded-lg bg-white px-4 pt-6 pb-5 text-sm text-gray-600 shadow">
              <div className="flex w-full items-center justify-between px-4">
                <dt className="font-semibold text-gray-900">{t("eventDate")}</dt>
                <dd>
                  <EventDate event={event} onAddEventDate={() => setDateModalVisible(true)} />
                </dd>
              </div>
              <div className="flex w-full items-center justify-between px-4 pt-4">
                <dt className="font-semibold text-gray-900">{t("eventUrl")}</dt>
                <dd className="md:mt-1" onClick={() => setEventPreviewModalVisible(true)}>
                  <div className="link flex flex-row items-end">
                    <div className="font-medium md:mt-1">{`${appDomain}/${eventId}`}</div>
                    <EyeIcon className="ml-1 h-5 w-5 text-gray-900" />
                  </div>
                </dd>
              </div>
            </div>

            <div className={`mt-8 flex items-center self-center ${isPremium ? "mb-12" : "mb-36"}`}>
              <div className="max-w-3xl divide-y divide-gray-300 overflow-hidden rounded-lg border border-gray-200 bg-base-200 sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                {actions.map((action, actionIdx) => (
                  <div
                    key={action.title}
                    className={classNames(
                      actionIdx === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : "",
                      "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500",
                    )}
                  >
                    <div>
                      <span
                        className={classNames(
                          action.iconBackground,
                          action.iconForeground,
                          "inline-flex rounded-lg p-3 ring-4 ring-white",
                        )}
                      >
                        <action.icon className="h-6 w-6" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-4">
                      <h3
                        className="text-base font-semibold leading-6 text-gray-900"
                        onClick={() => persistScrollPosition()}
                      >
                        {action.onClick ? (
                          <div>
                            <a onClick={action.onClick}>
                              <span className="absolute inset-0" aria-hidden="true" />
                              {action.title}
                            </a>
                          </div>
                        ) : (
                          <Link to={`${action.href}`} className="focus:outline-none">
                            <div>
                              {/* Extend touch target to entire panel */}
                              <span className="absolute inset-0" aria-hidden="true" />
                              {action.title}
                            </div>
                          </Link>
                        )}
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">{action.description}</p>
                    </div>
                    <span
                      className="pointer-events-none absolute right-6 top-6 text-gray-200 group-hover:text-gray-400"
                      aria-hidden="true"
                    >
                      <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
